/* #region Fonts */
@import url("https://use.typekit.net/kos8vpj.css");

@font-face {
  font-family: "CaslonClassico";
  src: url("../fonts/CaslonClassico-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaslonClassico";
  src: url("../fonts/CaslonClassico-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* #endregion */

/* #region Variables */
$color-info: #e0e0d9;
$color-warning: #dc582a;
$color-darker: #28385e;
$color-base: #28385e;
$color-dark: #121212;
$color-text: #28385e;

$color-pastel: #ef9988;
$color-blue: #ef9988;
$color-brown: #f5f5f7;
$color-green: #e0e0d9;
$color-grey: #f0f0ec;
$color-light-blue: #b6c1cb;

$color-primary: #ef9988;
$color-secondary: #28385e;
$color-light: #ffffff;
$color-white: #ffffff;
/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
body {
  font-family: "CaslonClassico", serif;
  font-weight: 400;
  font-size: rem-calc(18);
  color: $color-darker;
  overflow-x: hidden;
  position: relative;
}
p {
  font-weight: 400;
}
a {
  font-weight: 400;
}
* {
  outline: none !important;
}
/* #endregion */

/* #region Typography */
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
h5,
h6,
.h5,
.h6 {
  font-family: "CaslonClassico", serif;
  font-weight: 400;
  text-transform: unset;
}
.h1,
h1 {
  font-size: rem-calc(50);
  line-height: 120%;
}
.h2,
h2 {
  font-size: rem-calc(42);
  line-height: 124%;
  margin-bottom: rem-calc(20);
}
.h3,
h3 {
  font-size: rem-calc(24);
  margin-bottom: rem-calc(20);
}
.h4,
h4 {
  line-height: 1.33334;
  margin-bottom: rem-calc(18);
}
p {
  margin-bottom: rem-calc(24);
}
ul,
ol {
  li {
    font-size: 1rem;
  }
}
p,
ul li,
ol li,
a {
  color: $color-darker;
}

[type="button"],
[type="submit"],
button {
  -webkit-appearance: none;
}
/* #endregion */
.bg {
  &-darkder {
    background-color: $color-darker;
  }
  &-pastel {
    background-color: $color-pastel;
  }
  &-pink {
    background-color: $color-pastel;
  }
  &-blue {
    background-color: $color-darker;
  }
  &-brown {
    background-color: $color-brown;
  }
  &-green {
    background-color: $color-green;
  }
  &-grey {
    background-color: $color-grey;
  }
  &-light-blue {
    background-color: $color-light-blue;
  }
}

.text {
  &-white {
    color: #fff;
  }
  &-darker {
    color: $color-darker !important;
  }
  &-pastel {
    color: $color-pastel;
  }
  &-pink {
    color: $color-pastel;
  }
  &-blue {
    color: $color-blue;
  }
  &-brown {
    color: $color-brown;
  }
  &-green {
    color: $color-green;
  }
}

.fw-300 {
  font-weight: 300 !important;
}
.fz-34 {
  font-size: rem-calc(34) !important;
  @media (max-width: 991px) {
    font-size: rem-calc(20) !important;
  }
}
