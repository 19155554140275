@charset "UTF-8";
/* #region Fonts */
@import url("https://use.typekit.net/kos8vpj.css");
@font-face {
  font-family: "CaslonClassico";
  src: url("../fonts/CaslonClassico-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CaslonClassico";
  src: url("../fonts/CaslonClassico-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

/* #endregion */
/* #region Variables */
/* #endregion */
/* #region Function */
/* #endregion */
/* #region Global */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

body {
  font-family: "CaslonClassico", serif;
  font-weight: 400;
  font-size: 1.125rem;
  color: #28385e;
  overflow-x: hidden;
  position: relative; }

p {
  font-weight: 400; }

a {
  font-weight: 400; }

* {
  outline: none !important; }

/* #endregion */
/* #region Typography */
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.home-desc-inner h2,
.h4 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  text-transform: uppercase; }

h5,
h6,
.h5,
.h6 {
  font-family: "CaslonClassico", serif;
  font-weight: 400;
  text-transform: unset; }

.h1,
h1 {
  font-size: 3.125rem;
  line-height: 120%; }

.h2,
h2 {
  font-size: 2.625rem;
  line-height: 124%;
  margin-bottom: 1.25rem; }


.h3,
.home-desc-inner h2,
h3 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem; }

.h4,
h4 {
  line-height: 1.33334;
  margin-bottom: 1.125rem; }

p {
  margin-bottom: 1.5rem; }

ul li,
ol li {
  font-size: 1rem; }

p,
ul li,
ol li,
a {
  color: #28385e; }

[type="button"],
[type="submit"],
button {
  -webkit-appearance: none; }

/* #endregion */
.bg-darkder {
  background-color: #28385e; }

.bg-pastel {
  background-color: #ef9988; }

.bg-pink {
  background-color: #ef9988; }

.bg-blue {
  background-color: #28385e; }

.bg-brown {
  background-color: #f5f5f7; }

.bg-green {
  background-color: #e0e0d9; }

.bg-grey {
  background-color: #f0f0ec; }

.bg-light-blue {
  background-color: #b6c1cb; }

.text-white, .articles-masonry-item.green .article-content * {
  color: #fff; }

.text-darker, .articles-masonry-item.darker .article-content * {
  color: #28385e !important; }

.text-pastel {
  color: #ef9988; }

.text-pink {
  color: #ef9988; }

.text-blue, .articles-masonry-item.blue .article-content * {
  color: #ef9988; }

.text-brown, .articles-masonry-item.brown .article-content * {
  color: #f5f5f7; }

.text-green {
  color: #e0e0d9; }

.fw-300 {
  font-weight: 300 !important; }

.fz-34 {
  font-size: 2.125rem !important; }
  @media (max-width: 991px) {
    .fz-34 {
      font-size: 1.25rem !important; } }

/* #region Layout */
.row:not(.no-gutters) {
  margin-left: -10px;
  margin-right: -10px; }
  .row:not(.no-gutters) .col,
  .row:not(.no-gutters) > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.section {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  .section-border {
    border-bottom: solid 1px rgba(245, 245, 247, 0.5); }
  .section .section {
    padding-top: 0;
    padding-bottom: 0; }
  .section-margin {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding: 3.125rem;
    border-radius: 1.875rem; }
    @media (max-width: 991px) {
      .section-margin {
        padding: 1.25rem 3.125rem; } }

.half-padding-top {
  padding-top: 2.5rem !important; }
  @media (max-width: 991px) {
    .half-padding-top {
      padding-top: 1.5625rem !important; } }

.half-padding-bottom {
  padding-bottom: 2.5rem !important; }
  @media (max-width: 991px) {
    .half-padding-bottom {
      padding-bottom: 1.5625rem !important; } }

.wave-bg {
  position: relative;
  z-index: 1; }
  .wave-bg .waving {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 100%;
    z-index: -1; }

.grey-bg {
  background-color: #f0f0ec; }

.brown-bg {
  background-color: rgba(245, 245, 247, 0.5); }

.light-blue-bg {
  background-color: rgba(165, 186, 201, 0.3); }

.section.darker-bg .feature-item p {
  color: #fff; }

.virtual-holder .grey-bg,
.virtual-holder .brown-bg,
.virtual-holder .section.darker-bg {
  background-color: #fff; }
  .virtual-holder .grey-bg .intro-wrap h4,
  .virtual-holder .grey-bg .intro-wrap small,
  .virtual-holder .brown-bg .intro-wrap h4,
  .virtual-holder .brown-bg .intro-wrap small,
  .virtual-holder .section.darker-bg .intro-wrap h4,
  .virtual-holder .section.darker-bg .intro-wrap small {
    color: #96a480; }
  .virtual-holder .grey-bg h2,
  .virtual-holder .grey-bg p,
  .virtual-holder .grey-bg span,
  .virtual-holder .brown-bg h2,
  .virtual-holder .brown-bg p,
  .virtual-holder .brown-bg span,
  .virtual-holder .section.darker-bg h2,
  .virtual-holder .section.darker-bg p,
  .virtual-holder .section.darker-bg span {
    color: #00313c; }

.home-carousel-wrap {
  position: relative;
  z-index: 1; }

/* #endregion */
/* #region Form */
.btn {
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 0.3125rem;
  padding: 0.8125rem 1.875rem;
  line-height: 150%;
  height: 50px;
  box-shadow: none;
  text-transform: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .btn.focus, .btn:focus {
    box-shadow: none !important; }
  .btn-primary {
    background-color: #ef9988;
    border-color: #ef9988; }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: transparent;
      border-color: #ef9988;
      color: #ef9988; }
  .btn-outline-primary {
    background-color: transparent;
    border-color: #ef9988;
    color: #ef9988; }
    .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle {
      background-color: #ef9988;
      border-color: #ef9988;
      color: #fff; }
    .btn-outline-primary.btn-has-icon {
      overflow: hidden;
      display: inline-flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0; }
      .btn-outline-primary.btn-has-icon .text {
        padding: 0.3125rem 0.9375rem;
        width: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .btn-outline-primary.btn-has-icon .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        color: #fff;
        background-color: #ef9988; }
  .btn-secondary {
    background-color: #28385e;
    border-color: #28385e;
    color: #fff; }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border-color: #28385e;
      color: #28385e; }
  .btn-outline-secondary {
    background-color: transparent;
    border-color: #28385e;
    color: #28385e; }
    .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-secondary.dropdown-toggle {
      background-color: #28385e;
      border-color: #28385e;
      color: #fff !important; }
    .btn-outline-secondary.btn-has-icon {
      overflow: hidden;
      display: inline-flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0; }
      .btn-outline-secondary.btn-has-icon .text {
        padding: 0.3125rem 0.9375rem;
        width: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .btn-outline-secondary.btn-has-icon .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        color: #fff;
        background-color: #28385e; }
  .btn-warning {
    background-color: #dc582a;
    border-color: #dc582a;
    color: #fff; }
    .btn-warning:hover, .btn-warning:focus, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active,
    .show > .btn-warning.dropdown-toggle {
      background-color: transparent;
      border-color: #dc582a;
      color: #dc582a; }
  .btn-outline-warning {
    background-color: transparent;
    border-color: #dc582a;
    color: #dc582a; }
    .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active,
    .show > .btn-outline-warning.dropdown-toggle {
      background-color: #dc582a;
      border-color: #dc582a;
      color: #fff; }
    .btn-outline-warning.btn-has-icon {
      overflow: hidden;
      display: inline-flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0; }
      .btn-outline-warning.btn-has-icon .text {
        padding: 0.3125rem 0.9375rem;
        width: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .btn-outline-warning.btn-has-icon .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        color: #fff;
        background-color: #dc582a; }
  .btn-outline {
    padding: 0.9375rem 2rem;
    background: transparent;
    border: solid 1px #dc582a;
    color: #dc582a; }
    .btn-outline:hover, .btn-outline:focus {
      color: #fff;
      background: #dc582a; }
  .btn-link {
    color: #28385e;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-decoration: none; }
    .btn-link svg {
      margin-left: 10px; }
    .btn-link:hover, .btn-link:focus {
      color: #202d4c; }
  .btn-w150 {
    min-width: 9.375rem; }
  .btn-w160 {
    min-width: 10rem; }
  .btn-highlight {
    background: #6bcdb2; }

.custom-control-label::before {
  background-color: #f7f7f8;
  border-color: rgba(40, 56, 94, 0.4); }

.custom-control-label .label-selected {
  display: none; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28385e;
  border-color: #28385e; }

.custom-control-input:checked ~ .custom-control-label .label-selected {
  display: inline; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem; }

.dropdown-toggle::after {
  margin-left: 0.625rem;
  border: 0;
  width: 0.75rem;
  height: 0.5rem;
  background: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%2300313C'%2F%3E%3C%2Fsvg%3E") no-repeat;
  background-size: 0.75rem 0.5rem;
  vertical-align: 0.0625rem;
  transition: transform 0.2s ease; }

.dropdown.show .dropdown-toggle::after {
  transform: rotateX(-180deg); }

.dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); }

.dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #ef9988;
    background: #fff; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ef9988 !important; }

.bootstrap-select {
  width: 100% !important;
  display: block !important; }
  .bootstrap-select > .dropdown-toggle {
    background-color: transparent;
    border-color: #28385e;
    box-shadow: none;
    font-weight: 400; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
      color: #28385e; }
    .bootstrap-select > .dropdown-toggle::after {
      margin-top: 0;
      width: 0.875rem;
      height: 0.875rem;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8594 7.76562L13.6375 7.54375C13.4906 7.39687 13.2531 7.39687 13.1062 7.54375L7.53125 13.1219V1.375C7.53125 1.16875 7.3625 1 7.15625 1H6.84375C6.6375 1 6.46875 1.16875 6.46875 1.375V13.1219L0.890625 7.54375C0.74375 7.39687 0.50625 7.39687 0.359375 7.54375L0.1375 7.76562C-0.00937499 7.9125 -0.00937499 8.15 0.1375 8.29688L6.73125 14.8938C6.87812 15.0406 7.11562 15.0406 7.2625 14.8938L13.8562 8.29688C14.0062 8.14687 14.0062 7.9125 13.8594 7.76562Z' fill='white'/%3E%3C/svg%3E%0A");
      background-size: contain; }
  .bootstrap-select .dropdown-menu li a:hover {
    color: #fff; }

.bootstrap-tagsinput {
  display: block;
  padding: 0.4375rem 0 0.375rem;
  color: #28385e;
  border-bottom: 1px solid rgba(40, 56, 94, 0.4);
  width: 100%;
  cursor: text; }
  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    font-size: 1rem;
    font-weight: 400;
    height: 2.25rem;
    color: #28385e; }
    .bootstrap-tagsinput input::placeholder {
      color: #28385e; }
    .bootstrap-tagsinput input:focus {
      border: none;
      box-shadow: none; }
  .bootstrap-tagsinput .tag {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.125rem;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.42857;
    padding: 0.5rem 0.625rem;
    color: #28385e; }
    .bootstrap-tagsinput .tag [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;
      vertical-align: -2px;
      display: inline-block; }
      .bootstrap-tagsinput .tag [data-role="remove"]:after {
        display: block;
        content: "×";
        padding: 0px 2px;
        font-size: 1.25rem;
        line-height: 1rem; }

/* #endregion */
/* #region Navbar */
.navtop-search {
  position: relative;
  margin-left: 0.9375rem;
  display: flex;
  align-items: center;
  margin-right: 0.625rem; }
  .navtop-search .form-control {
    position: absolute;
    right: -0.625rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    height: 2.5rem;
    border-radius: 0.125rem;
    border: 0;
    width: 0;
    font-size: 0.75rem;
    line-height: 1.538462;
    font-weight: 400;
    transition: all 0.3s ease;
    box-shadow: none; }
  .navtop-search .btn-search {
    position: relative;
    z-index: 10;
    display: block;
    padding: 0.09375rem;
    background-color: transparent;
    border: 0;
    color: #28385e; }
    .navtop-search .btn-search:hover {
      color: #fff; }
    .navtop-search .btn-search svg {
      display: block; }
  .navtop-search.active .form-control {
    width: 250px;
    padding: 0.3125rem 2.25rem 0.3125rem 0.75rem;
    background: #f1f2f3; }
  .navtop-search.active .btn-search {
    color: #28385e !important; }
  @media (max-width: 576px) {
    .navtop-search.active {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translatey(-50%);
      z-index: 1; }
      .navtop-search.active .form-control {
        width: calc(100vw - 20px) !important;
        height: 2.8rem;
        font-size: 1rem; } }

.navbar {
  padding: 1.875rem 1.25rem;
  transition: all 0.15s linear; }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    margin-right: 1.5rem; }
    .navbar-brand:hover {
      color: #fff; }
    .navbar-brand svg {
      display: block;
      transition: color 0.2s ease; }
    .navbar-brand img {
      width: 200px; }
      .navbar-brand img.scroll {
        display: none; }
  .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 0.875rem; }
    .navbar-nav .nav-item .nav-link:hover {
      color: #ef9988; }
    .navbar-nav .nav-item .nav-link.logo:hover {
      color: #fff !important; }
  .navbar-nav .nav-item.active .nav-link {
    color: #ef9988; }
  .navbar-nav .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E"); }
  .navbar-nav .dropdown-toggle:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%23EAA794'%2F%3E%3C%2Fsvg%3E"); }
  .navbar-nav .dropdown-item {
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 0.5rem 0.9375rem;
    text-transform: none; }
  .navbar.scrolled, .navbar.navbar-white {
    background-color: #28385e;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 15px; }
    .navbar.scrolled .navbar-nav .nav-item .nav-link, .navbar.navbar-white .navbar-nav .nav-item .nav-link {
      color: #fff; }
      .navbar.scrolled .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-white .navbar-nav .nav-item .nav-link:hover {
        color: #ef9988; }
    .navbar.scrolled .navbar-nav .nav-item.active .nav-link, .navbar.navbar-white .navbar-nav .nav-item.active .nav-link {
      color: #ef9988; }
    .navbar.scrolled .navbar-nav .dropdown-toggle::after, .navbar.navbar-white .navbar-nav .dropdown-toggle::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.582.043L.043.582 4.73 5.27l.27.257.27-.257L9.957.582 9.418.043 5 4.461.582.043z' fill='%23fff'/%3E%3C/svg%3E"); }
    .navbar.scrolled .navbar-nav .dropdown-toggle:hover::after, .navbar.navbar-white .navbar-nav .dropdown-toggle:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%23EAA794'%2F%3E%3C%2Fsvg%3E"); }
    .navbar.scrolled .navbar-brand, .navbar.navbar-white .navbar-brand {
      color: #fff; }
      .navbar.scrolled .navbar-brand img.no-scroll, .navbar.navbar-white .navbar-brand img.no-scroll {
        display: none; }
      .navbar.scrolled .navbar-brand img.scroll, .navbar.navbar-white .navbar-brand img.scroll {
        display: block; }
    .navbar.scrolled .hamburger-inner, .navbar.scrolled .hamburger-inner::before, .navbar.scrolled .hamburger-inner::after, .navbar.navbar-white .hamburger-inner, .navbar.navbar-white .hamburger-inner::before, .navbar.navbar-white .hamburger-inner::after {
      background-color: #fff; }
    .navbar.scrolled .navtop-search .btn-search:hover, .navbar.navbar-white .navtop-search .btn-search:hover {
      color: #ef9988; }
    .navbar.scrolled .contact-wrap .btn-tel:hover, .navbar.navbar-white .contact-wrap .btn-tel:hover {
      color: #ef9988; }

.contact-wrap {
  display: flex;
  align-items: center;
  justify-content: center; }
  .contact-wrap .btn-tel {
    color: #28385e;
    font-size: 0.9375rem;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-decoration: none; }
    .contact-wrap .btn-tel svg {
      margin-right: 0.3125rem; }
    .contact-wrap .btn-tel:hover {
      color: #fff; }

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 -0.875rem 0 0.5rem;
  overflow: visible;
  display: none;
  padding: 0.9375rem 0.875rem; }
  .hamburger-box {
    width: 22px;
    height: 20px;
    display: block;
    position: relative; }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 22px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -6px;
      transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
    .hamburger-inner::after {
      bottom: -6px;
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
    .hamburger.is-active .hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  z-index: 1040;
  display: none;
  overflow: hidden;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  background-color: #fff; }
  .navigation-open .navigation {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s; }
  .navigation-header {
    padding: 1rem 1.5rem; }
  .navigation .scrollable {
    max-height: 100vh;
    overflow: auto;
    padding-bottom: 20px; }
  .navigation .close {
    display: block;
    float: none;
    color: #28385e;
    font-family: sans-serif;
    font-size: 2.5rem;
    line-height: 1;
    margin-left: auto;
    transition: all 0.1s ease;
    opacity: 1 !important;
    font-weight: 300; }
  .navigation-body {
    overflow: hidden;
    position: relative;
    z-index: 2; }
  .navigation-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1034;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease; }
    .navigation-open .navigation-backdrop {
      opacity: 1;
      visibility: visible; }
  .navigation .nav-link {
    font-size: 0.875rem;
    text-transform: unset;
    line-height: 1.538462;
    font-weight: 400;
    padding: 0.625rem 1.5rem;
    color: #28385e; }
    .navigation .nav-link[data-toggle="collapse"] {
      padding-right: 2.5rem; }
  .navigation .collapse-body .nav-link {
    padding-left: 2.25rem; }

.navigation-open {
  overflow: hidden !important;
  height: 100% !important; }

[data-toggle="collapse"] {
  position: relative; }
  [data-toggle="collapse"] .icon-chevron-down {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    transition: transform 0.2s ease; }
  [data-toggle="collapse"]:not(.collapsed) .icon-chevron-down {
    transform: rotateX(-180deg); }

/* #endregion */
/* #region Big hero */
.big-header {
  display: flex;
  height: 700px;
  position: relative;
  z-index: 3; }
  .big-header-carousel {
    width: 100%; }
    .big-header-carousel .header-carousel {
      height: 100%; }
      .big-header-carousel .header-carousel .owl-stage-outer, .big-header-carousel .header-carousel .owl-stage, .big-header-carousel .header-carousel .owl-item, .big-header-carousel .header-carousel .owl-item-inner {
        height: 100%; }
      .big-header-carousel .header-carousel .owl-item-inner {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        background-color: #e0e0d9; }
        .big-header-carousel .header-carousel .owl-item-inner img {
          width: 80%;
          min-height: 100%;
          object-fit: cover;
          margin-left: auto; }
      .big-header-carousel .header-carousel .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 50px; }
        .big-header-carousel .header-carousel .owl-dots .owl-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: rgba(40, 56, 94, 0.4);
          margin: 0.625rem;
          transition: background-color 0.2s ease-out; }
          .big-header-carousel .header-carousel .owl-dots .owl-dot.active, .big-header-carousel .header-carousel .owl-dots .owl-dot:hover {
            background: #28385e; }
      .big-header-carousel .header-carousel .header-content {
        z-index: 4;
        position: absolute;
        left: 50px;
        top: 50%; }
  .big-header .h1 {
    z-index: 1;
    font-size: 3.75rem;
    font-weight: 400;
    text-transform: unset;
    white-space: nowrap;
    color: #28385e;
    z-index: 4; }
  .big-header .quick-access {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 90;
    background: #e0e0d9;
    color: #28385e;
    padding: 1.875rem;
    width: 240px;
    border-radius: 3px; }
    .big-header .quick-access span {
      font-size: 1.375rem;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 1.125rem; }
    .big-header .quick-access ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .big-header .quick-access ul li {
        border-bottom: solid 1px white;
        padding: 0; }
        .big-header .quick-access ul li::marker {
          display: none;
          content: none; }
        .big-header .quick-access ul li a {
          color: #28385e;
          text-decoration: none;
          font-size: 1.125rem;
          font-weight: 400;
          text-transform: unset;
          display: block;
          padding: 0.75rem 0;
          width: 100%;
          position: relative;
          transition: color 0.2s ease-out; }
          .big-header .quick-access ul li a:hover, .big-header .quick-access ul li a:focus {
            color: #ffffff; }
        .big-header .quick-access ul li:last-child {
          border-bottom: 0;
          padding-bottom: 0; }
          .big-header .quick-access ul li:last-child a {
            padding-bottom: 0; }

/* #endregion */
/* #region Footer */
.subscribe {
  color: #28385e;
  background-color: #e0e0d9;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  @media (max-width: 991px) {
    .subscribe {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .subscribe-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    @media (max-width: 991px) {
      .subscribe-inner {
        flex-direction: column; } }
    .subscribe-inner h2 {
      width: 100%;
      text-transform: uppercase; }
      @media (max-width: 991px) {
        .subscribe-inner h2 {
          margin-bottom: 1.25rem;
          font-size: 1.875rem; } }
    .subscribe-inner-form {
      position: relative;
      margin-bottom: 1.25rem;
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      column-gap: 0.625rem; }
      .subscribe-inner-form input {
        padding: 0.8125rem 1.5rem;
        width: 100%;
        background: #ffffff;
        color: #28385e;
        font-size: 1.125rem;
        font-weight: 400;
        border: none;
        border-radius: 0.3125rem;
        height: auto; }
        @media (max-width: 991px) {
          .subscribe-inner-form input {
            max-width: 100%; } }
        .subscribe-inner-form input::placeholder {
          color: #28385e;
          opacity: 0.5; }
      .subscribe-inner-form .btn-submit {
        flex-shrink: 0; }
    .subscribe-inner .custom-checkbox .custom-control-label {
      font-size: 1.125rem; }
      .subscribe-inner .custom-checkbox .custom-control-label::before {
        border-radius: 0.1875rem;
        background: transparent;
        border: solid 1px #28385e; }
    .subscribe-inner p {
      margin: 0;
      font-size: 1.125rem;
      font-weight: 300; }
    .subscribe-inner a {
      font-weight: 300;
      color: #28385e; }
  .subscribe ~ .footer {
    border-radius: 0 !important; }

.footer {
  background: #28385e;
  color: #ffffff;
  padding-top: 5rem; }
  @media (max-width: 991px) {
    .footer {
      padding-top: 2.5rem; } }
  .footer-top {
    padding-bottom: 1.25rem; }
    .footer-top p {
      font-size: 1.125rem;
      margin-bottom: 0.3125rem;
      color: #ffffff; }
      .footer-top p span {
        display: inline-block;
        color: #ffffff;
        font-weight: 400;
        margin-right: 0.3125rem; }
    .footer-top h3 {
      margin-bottom: 1.5625rem; }
    .footer-top a {
      color: #ffffff; }
      .footer-top a:hover {
        color: #ffffff; }
  .footer-logo {
    display: inline-block;
    color: #ffffff;
    margin-bottom: 0; }
    .footer-logo:hover {
      color: #ffffff; }
    .footer-logo > img {
      max-width: 100%; }
    @media (max-width: 991px) {
      .footer-logo {
        margin-bottom: 1.5625rem; } }
  .footer-map {
    margin-top: 1.25rem;
    margin-bottom: 3.75rem; }
    @media (max-width: 991px) {
      .footer-map {
        margin-top: 0.625rem;
        margin-bottom: 0; } }
    .footer-map .col-12 {
      display: flex; }
      .footer-map .col-12 p {
        margin-top: auto;
        margin-bottom: 0;
        opacity: 0.5;
        font-size: 0.8125rem; }
        @media (max-width: 991px) {
          .footer-map .col-12 p {
            margin-bottom: 1.25rem; } }
      .footer-map .col-12 .footer-map-img {
        width: 100%;
        height: auto; }
      .footer-map .col-12 .footer-map-wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 50%; }
      .footer-map .col-12 .footer-map-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
  .footer-list {
    margin: 0;
    margin-bottom: 3.75rem;
    padding: 0;
    list-style: none; }
    @media (max-width: 991px) {
      .footer-list {
        margin-bottom: 1.875rem; } }
    .footer-list li {
      margin-bottom: 0.9375rem; }
      .footer-list li a {
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 400;
        text-decoration: none;
        text-transform: unset; }
        .footer-list li a:hover, .footer-list li a:focus {
          color: #ffffff; }
  .footer-bottom {
    padding-bottom: 1.875rem; }
    .footer-bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.875rem;
      border-top: 1px solid rgba(255, 255, 255, 0.3); }
      .footer-bottom-inner p {
        margin: 0;
        font-size: 1.125rem;
        color: #ffffff; }
      .footer-bottom-inner a {
        position: relative;
        text-decoration: none !important;
        color: #ffffff;
        text-decoration: underline;
        text-underline-offset: 2px; }
        .footer-bottom-inner a:hover {
          color: #ffffff; }
      .footer-bottom-inner .note {
        max-width: 50%; }
      .footer-bottom-inner .copyright {
        text-align: right;
        font-weight: 300;
        color: #ffffff; }
        .footer-bottom-inner .copyright small {
          font-size: 0.8125rem; }
        @media (max-width: 991px) {
          .footer-bottom-inner .copyright {
            text-align: center; }
            .footer-bottom-inner .copyright p {
              margin: 0 auto; } }
  .footer .btn-wrap {
    margin-top: 0.625rem;
    margin-bottom: 1.5625rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .footer .socials {
    margin-top: 0.9375rem;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .footer .socials a {
      margin: 5px;
      color: #ffffff; }
      .footer .socials a:hover {
        color: #ef9988; }
  .footer .sm-text {
    margin-top: 1.25rem;
    font-size: 0.9375rem; }
    .footer .sm-text a {
      color: inherit;
      text-decoration: underline; }

/* #endregion */
/* #region Intro */
.intro-wrap {
  margin-bottom: 1.875rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column; }
  .intro-wrap.center {
    text-align: center;
    align-items: center; }
  .intro-wrap small {
    display: inline-block;
    font-size: 0.9375rem;
    line-height: calc(20 / 14);
    font-weight: 400;
    color: #ef9988;
    text-transform: unset;
    margin-bottom: 0.625rem; }
  .intro-wrap h4 {
    font-family: inherit;
    font-size: 1.125rem;
    font-weight: 700;
    color: #28385e;
    text-transform: unset; }
  .intro-wrap h2 {
    font-size: 2.625rem;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    color: #28385e; }
  .intro-wrap p {
    display: inline-block;
    max-width: 600px;
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
    color: #28385e; }
  .intro-wrap .btn-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% + 15px);
    margin-left: -15px; }
    .intro-wrap .btn-wrap a {
      margin: 5px 15px; }
      .intro-wrap .btn-wrap a.link {
        font-size: 1.125rem;
        font-weight: 400;
        text-decoration: none;
        color: #28385e; }

.cta-wrap {
  text-align: center;
  padding-top: 1.875rem; }

/* #endregion */
/* #region Text w Image */
.has-bg {
  background-repeat: no-repeat;
  background-position-y: 65px;
  background-size: 65% auto; }
  .has-bg.right {
    background-position-x: 100%; }
  .has-bg.left {
    background-position-x: 0; }

.textside {
  background: #fff;
  padding: 2.5rem;
  margin-top: 3.125rem;
  position: relative;
  z-index: 1;
  margin-bottom: 1.875rem; }
  .textside p {
    font-size: 1.125rem;
    margin-bottom: 1.5625rem; }
  .textside ul {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5625rem;
    list-style: none; }
    .textside ul li {
      padding-left: 1.875rem;
      position: relative;
      margin-bottom: 0.625rem; }
      .textside ul li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
  .textside *:last-child {
    margin-bottom: 0; }

.imgside {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  /*&::after{
      content: "";
      position: absolute;
      right: -5%;
      bottom: 10%;
      border: 2px solid $color-pastel;
      width: 200px;
      height: 250px;
      border-radius: 200px 200px 0px 0px;
      @media (max-width: 768px) {
        width: 100px;
        height: 120px;
      }
    }*/ }
  .imgside.left::after {
    left: -5%;
    right: auto; }
  .imgside img {
    width: 100%;
    height: auto;
    object-fit: cover;
    /*@media (max-width: 1190px) {
        min-height: 500px;
      }
      @media (max-width: 768px) {
        min-height: 300px !important;
      }
      @media (min-height: 768px) {
        min-height: 700px;
        border-radius: 400px 400px 0 0;
      }*/ }

/* #endregion */
/* #region Map */
.content-map {
  width: auto;
  padding-left: 4.0625rem;
  margin-top: 1.875rem;
  padding-right: 4.0625rem;
  position: relative; }
  .content-map-inner {
    width: 100%;
    height: 650px; }
  .content-map-filter {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 240px;
    background: #f5f5f7;
    padding: 1.875rem;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); }
    .content-map-filter span {
      font-size: 1.125rem;
      display: inline-block;
      margin-bottom: 1.25rem; }
    .content-map-filter .custom-checkbox {
      margin-right: 0 !important;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      border-bottom: solid 1px rgba(40, 56, 94, 0.2); }
      .content-map-filter .custom-checkbox:last-child {
        border-bottom: 0; }
      .content-map-filter .custom-checkbox .custom-control-label {
        font-size: 0.9375rem;
        font-weight: 400;
        text-transform: unset; }
      .content-map-filter .custom-checkbox .custom-control-input:checked ~ .custom-control-label {
        color: #fff; }
      .content-map-filter .custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        background-color: #28385e;
        border-color: #28385e; }
      .content-map-filter .custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #28385e;
        box-shadow: 0 0 0 0.2rem rgba(40, 56, 94, 0.25); }
      .content-map-filter .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(40, 56, 94, 0.25); }
      .content-map-filter .custom-checkbox .custom-control-label:before {
        border-radius: 0; }

.mapItem {
  background: #f6f5f2; }
  .mapItem-img {
    position: relative;
    width: 100%;
    padding-top: 45%;
    overflow: hidden; }
    .mapItem-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover; }
  .mapItem-content {
    padding: 1.25rem 1.25rem; }
    .mapItem-content h3 {
      font-family: "CaslonClassico", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 0.9375rem; }
    .mapItem-content p {
      margin-bottom: 1.25rem;
      font-size: 1.125rem; }
    .mapItem-content ul {
      margin: 0;
      margin-bottom: 0.625rem;
      padding: 0;
      list-style: none;
      column-count: 2;
      column-gap: 20px; }
      .mapItem-content ul li {
        padding-left: 25px;
        position: relative;
        margin-bottom: 0.625rem;
        font-size: 1.125rem; }
        .mapItem-content ul li::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
    .mapItem-content .btn-wrap {
      margin-left: -10px;
      width: calc(100% + 20px); }
      .mapItem-content .btn-wrap a {
        margin: 0.3125rem 0.625rem; }
        .mapItem-content .btn-wrap a.link {
          font-size: 1.125rem;
          font-weight: 400;
          color: #28385e;
          text-decoration: none; }

.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0; }

.gm-style .gm-style-iw-d {
  overflow: auto !important; }

.gm-style .gm-style-iw-a button[aria-label="Close"] {
  left: 0 !important;
  top: 0 !important; }
  .gm-style .gm-style-iw-a button[aria-label="Close"] img {
    width: 22px !important;
    height: 22px !important; }

.gm-style .gm-style-iw-t::after {
  display: none; }

.filter-dropdown .dropdown-toggle {
  border-radius: 0;
  border: 0;
  background: #ffffff;
  padding: 0.8125rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #28385e !important;
  text-transform: unset;
  border-radius: 0.625rem; }
  .filter-dropdown .dropdown-toggle:focus {
    outline: none !important; }

.filter-dropdown .dropdown-menu li a {
  padding: 0.375rem 0.75rem; }
  .filter-dropdown .dropdown-menu li a.active, .filter-dropdown .dropdown-menu li a:hover, .filter-dropdown .dropdown-menu li a:active {
    background: #ef9988;
    color: #fff !important; }
  .filter-dropdown .dropdown-menu li a span.text {
    margin-bottom: 0;
    font-size: 0.9375rem;
    text-transform: unset; }

/* #endregion */
/* #region Masonry */
.articles-masonry {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 1.875rem;
  margin-bottom: 1.25rem; }

.articles-masonry-item {
  margin: 0 auto -1.25rem;
  display: block;
  width: calc(33.33333% - 40px);
  margin-bottom: 1.25rem; }
  .articles-masonry-item.no-image .article-wrap {
    min-height: 350px;
    background-color: #ef9988; }
    .articles-masonry-item.no-image .article-wrap::before {
      display: none; }
    .articles-masonry-item.no-image .article-wrap::after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#00313c'%2F%3E%3C%2Fsvg%3E") no-repeat; }
    .articles-masonry-item.no-image .article-wrap .article-content {
      color: #28385e; }
  .articles-masonry-item:hover {
    text-decoration: none; }

.article-wrap {
  position: relative;
  /*&::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80%;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 0;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 30px;
        right: 30px;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#fff'%2F%3E%3C%2Fsvg%3E") no-repeat;
        background-position: center;
      }*/ }
  .article-wrap img {
    width: 100%;
    border-radius: 0.3125rem 0.3125rem 0 0; }
  .article-wrap:hover .image-card img {
    transform: translate(-50%, -50%) scale(1.1); }

.article-content {
  /*position: absolute;
      z-index: 1;
      bottom: rem-calc(30);
      left: rem-calc(30);
      text-decoration: none;
      color: #fff;
      max-width: calc(100% - 100px);
      *:last-child {
        margin-bottom: 0;
      }
      /*p {
        color: #fff;
      }*/
  border: 1px solid #e9e7e1;
  padding: 1.25rem; }
  .article-content small {
    font-size: 1.125rem;
    font-weight: 400;
    text-transform: unset;
    margin-bottom: 0.625rem; }
  .article-content h3 {
    font-size: 1.5rem;
    font-family: "CaslonClassico", sans-serif;
    font-weight: 400;
    text-transform: unset;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center; }
  .article-content p {
    font-size: 1.125rem;
    margin-bottom: 2.5rem; }
  .article-content .read-more {
    margin-top: auto;
    font-size: 1.125rem;
    font-weight: 400;
    text-decoration: none !important;
    color: #ef9988;
    display: inline-flex;
    align-items: center; }

.article-message {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 0.4375rem; }
  .article-message span {
    font-size: 0.8125rem;
    font-weight: 400; }

/* #endregion */
/* #region Filters */
.filter-bar {
  background: #e0e0d9;
  padding: 1.25rem;
  border-radius: 3px;
  color: #28385e; }
  .filter-bar span {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    display: inline-block;
    text-transform: unset; }
  .filter-bar .bootstrap-select {
    margin-bottom: 0 !important; }
    .filter-bar .bootstrap-select .btn.dropdown-toggle {
      font-size: 1.125rem;
      padding: 0.5rem 1rem;
      background-color: #ffffff;
      border-radius: 0.1875rem;
      height: 39px; }
      .filter-bar .bootstrap-select .btn.dropdown-toggle::after {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'14'%20height%3D'8'%20viewBox%3D'0%200%2014%208'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M1.3125%200.86377L0.638672%201.5376L6.49805%207.39697L6.83496%207.71924L7.17188%207.39697L13.0312%201.5376L12.3574%200.86377L6.83496%206.38623L1.3125%200.86377Z'%20fill%3D'%2328385E'%2F%3E%3C%2Fsvg%3E") center no-repeat; }

/* #endregion */
/* #region Home-item */
.home-carousel-wrap {
  margin-top: 1.875rem; }
  .home-carousel-wrap > .container {
    position: relative; }
  .home-carousel-wrap .home-carousel,
  .home-carousel-wrap .home-carousel-two {
    position: static; }
  .home-carousel-wrap .owl-nav .owl-prev,
  .home-carousel-wrap .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background-color: #ef9988 !important;
    display: flex;
    align-items: center;
    justify-content: center; }
    .home-carousel-wrap .owl-nav .owl-prev.disabled,
    .home-carousel-wrap .owl-nav .owl-next.disabled {
      opacity: 0.4; }
    .home-carousel-wrap .owl-nav .owl-prev span,
    .home-carousel-wrap .owl-nav .owl-next span {
      font-size: 2.25rem;
      font-family: "CaslonClassico", sans-serif;
      font-weight: 300;
      color: #fff; }
  .home-carousel-wrap .owl-nav .owl-prev {
    left: 0; }
  .home-carousel-wrap .owl-nav .owl-next {
    right: 0; }

.homeItem {
  background: #ffffff;
  margin-bottom: 1.875rem;
  position: relative;
  border-radius: 0.625rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: solid 1px #dddddd;
  overflow: hidden; }
  .homeItem > span {
    position: absolute;
    right: -50%;
    top: 17%;
    z-index: 1;
    background: #28385e;
    padding: 5px;
    color: #fff;
    text-transform: unset;
    font-size: 0.875rem;
    transform: rotate(45deg) translateX(-15%);
    width: 100%;
    text-align: center; }
  .homeItem-img {
    position: relative;
    width: 100%;
    padding-top: 60%;
    display: block;
    overflow: hidden;
    border-radius: 0.625rem; }
    .homeItem-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%); }
  .homeItem-content {
    padding: 1.25rem 1.875rem; }
  .homeItem-features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1.875rem;
    padding-bottom: 1.25rem;
    min-height: 45px; }
    .homeItem-features span {
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .homeItem-features span svg {
        color: #ef9988; }
      .homeItem-features span img {
        width: auto !important;
        margin-right: 0.3125rem; }
  .homeItem-inner h3 {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: unset;
    margin-bottom: 0.625rem; }
    .homeItem-inner h3 a {
      text-decoration: none;
      color: #28385e;
      font-weight: 500; }
      .homeItem-inner h3 a:hover {
        text-decoration: none; }
  .homeItem-inner p {
    font-size: 1.125rem;
    margin-bottom: 1.875rem; }
  .homeItem-inner .location {
    margin-bottom: 1.875rem; }
    .homeItem-inner .location:empty {
      display: none; }
  .homeItem-inner ul {
    margin-bottom: 1.875rem;
    padding: 0;
    list-style: none; }
    .homeItem-inner ul li {
      padding-left: 1.875rem;
      position: relative;
      margin-bottom: 0.625rem; }
      .homeItem-inner ul li::before {
        position: absolute;
        top: 1px;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat; }
  .homeItem-inner .link-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .homeItem-inner .link-wrap .link {
      font-weight: 400;
      font-size: 1.125rem;
      text-decoration: none;
      color: #28385e;
      display: inline-block;
      margin-right: 1.875rem;
      text-decoration: underline;
      text-underline-offset: 2px;
      text-transform: unset; }
      .homeItem-inner .link-wrap .link:hover, .homeItem-inner .link-wrap .link:focus {
        color: #ef9988; }
      .homeItem-inner .link-wrap .link:last-child {
        margin-right: 0; }

/* #endregion */
/* #region Review */
.review-wrap {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  margin-bottom: 4.375rem;
  background-color: #f6f5f2;
  position: relative; }
  .review-wrap .review-wrap-bg {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../img/bg-review.png");
    background-repeat: no-repeat;
    background-size: 135%;
    background-position-x: 100%;
    background-position-y: center;
    width: 50%;
    height: 100%; }
    @media screen and (max-width: 991px) {
      .review-wrap .review-wrap-bg {
        display: none; } }
  .review-wrap.left .review-wrap-bg {
    right: unset;
    left: 0; }
  .review-wrap .review-carousel {
    position: static; }
  .review-wrap .owl-dots {
    position: absolute;
    top: calc(100% + 40px);
    left: 50%;
    transform: translateX(-50%); }
    .review-wrap .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(40, 56, 94, 0.4);
      margin: 5px 10px; }
      .review-wrap .owl-dots .owl-dot.active {
        background-color: #28385e; }

.review-text {
  position: relative;
  padding-top: 3.75rem;
  margin-right: 3.75rem;
  overflow: visible; }
  .review-text::before, .review-text::after {
    position: absolute;
    color: #e0e0d9;
    font-size: 6.25rem;
    z-index: 0; }
  .review-text::before {
    content: "“";
    top: -30px;
    left: 0; }
  .review-text::after {
    content: "”";
    bottom: -85px;
    right: 0; }
  .review-text p {
    font-size: 1.125rem;
    margin-bottom: 1.875rem; }
  .review-text h3 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0; }

.review-media {
  width: 100%;
  padding-top: 30%;
  position: relative;
  overflow: hidden;
  max-width: 700px;
  margin: 0 auto; }
  @media (max-width: 1140px) {
    .review-media {
      padding-top: 65%;
      max-width: 100%; } }
  .review-media img,
  .review-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border: 0; }
  .review-media .btn-play {
    width: 48px;
    height: 48px;
    min-height: unset;
    object-fit: unset;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
    @media (min-width: 992px) {
      .review-media .btn-play {
        width: 96px;
        height: 96px; } }
    .review-media .btn-play img {
      width: 100%;
      height: 100%;
      position: static;
      transform: unset; }

/* #endregion */
.social-list {
  margin: 20px 0px;
  padding: 0px;
  list-style: none; }

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 52px;
  min-width: 96%;
  width: 100%; }

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.875rem;
  margin-top: 1.25rem; }
  .feature-item p {
    max-width: 280px;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: unset; }

.feature-img {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.875rem; }
  .feature-img img {
    width: 100%;
    height: auto;
    object-fit: cover; }

p.message.error,
span.message.required {
  color: #ff0000; }

.userform .field.recaptcha {
  padding-left: 10px;
  margin-bottom: 20px; }

.custom-modal .modal-dialog {
  flex: 1;
  min-height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center; }

.custom-modal .modal-content {
  border-radius: 0;
  border: 0; }
  .custom-modal .modal-content button.close {
    font-family: sans-serif;
    font-weight: 300;
    font-size: 2.3125rem;
    color: #fff;
    position: absolute;
    bottom: calc(100% + 3px);
    left: calc(100% + 3px);
    opacity: 1;
    text-shadow: none; }
    .custom-modal .modal-content button.close span {
      z-index: 1; }
    .custom-modal .modal-content button.close:hover {
      color: #ef9988; }

.custom-modal .modal-review {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden; }
  .custom-modal .modal-review img,
  .custom-modal .modal-review iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border: 0; }

.breadcrumbs {
  padding-top: 2.1875rem;
  padding-bottom: 0rem; }
  .breadcrumbs * {
    font-size: 0.875rem; }
  .breadcrumbs-link {
    color: #000;
    display: inline-block;
    position: relative;
    margin-right: 1.25rem; }
    .breadcrumbs-link::after {
      content: '/';
      position: absolute;
      right: -15px;
      top: 0;
      pointer-events: none; }
    .breadcrumbs-link:hover {
      color: #f5f5f7;
      text-decoration: none; }
      .breadcrumbs-link:hover::after {
        color: #000; }

@media (min-width: 1300px) {
  /* #region Layout */
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1230px; }
  /* #endregion */ }

@media (min-width: 1500px) {
  /* #region Layout */
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1330px; }
  /* #endregion */ }

@media (max-width: 1399px) {
  /* #region Navbar */
  .navtop,
  .navbar {
    padding-left: 20px;
    padding-right: 20px; }
  .navbar-brand {
    margin-right: 1rem; }
  .navbar .btn {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    white-space: nowrap; }
  .navbar-nav .nav-item .nav-link {
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .navtop-search {
    margin-left: 0;
    margin-right: 0; }
  .contact-wrap .btn-tel {
    font-size: 0.8125rem;
    margin: 0 0.625rem; }
  .contact-wrap .btn {
    font-size: 0.8125rem;
    padding: 0.75rem; }
    .contact-wrap .btn.btn-w160 {
      min-width: 110px; }
  .navbar-brand svg,
  .navbar-brand img {
    width: 140px;
    height: auto; }
  .navbar.navbar-white,
  .navbar.scrolled {
    padding: 10px 15px; }
  /* #endregion */ }

@media (max-width: 991px) {
  /* #region Layout */
  /* #endregion */
  /* #region Typography */
  .h1,
  h1 {
    font-size: 2.375rem; }
  .h2,
  h2 {
    font-size: 1.875rem; }
  
  .h3,
  .home-desc-inner h2,
  h3 {
    font-size: 1.625rem; }
  .h4,
  h4 {
    font-size: 1.25rem; }
  /* #endregion */
  /* #region Navbar */
  .navbar-nav {
    display: none; }
  .navbar .btn {
    padding-left: 1.375rem;
    padding-right: 1.375rem; }
  .hamburger {
    display: block; }
  /* #endregion */
  .navtop-search .btn-search {
    color: #fff; }
  .contact-wrap .icon-phone {
    color: #fff;
    margin-left: 0.9375rem; }
  .phone-mobile {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.875rem; }
    .phone-mobile a {
      display: block;
      width: 100%;
      height: 50px;
      background-color: #fff;
      border-radius: 0.1875rem;
      color: #0091ff;
      text-align: left;
      line-height: 3.125rem;
      margin-bottom: 1.25rem;
      font-size: 1.125rem;
      padding: 0 1.25rem; }
      .phone-mobile a:hover {
        color: #0091ff;
        text-decoration: none; }
      .phone-mobile a.cancel {
        text-align: center;
        font-weight: 400; }
      .phone-mobile a svg {
        margin-right: 0.9375rem; }
        .phone-mobile a svg fill {
          color: #d2d2d2; }
  .big-header {
    height: auto;
    min-height: 450px; }
    .big-header-carousel {
      width: 100%; }
      .big-header-carousel .header-carousel .owl-stage-outer:after {
        width: 100%; }
      .big-header-carousel .owl-item-inner::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(40, 56, 94, 0.2);
        z-index: 2; }
      .big-header-carousel .owl-dots {
        width: 100%;
        left: 0 !important;
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center; }
    .big-header-content {
      width: 100%;
      position: absolute;
      z-index: 3;
      background: transparent;
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      padding: 20px 30px; }
      .big-header-content .h1 {
        font-size: 3.125rem;
        color: #fff;
        white-space: normal; }
      .big-header-content p {
        margin-top: 1.25rem;
        margin-bottom: 0.9375rem;
        font-size: 1.25rem;
        color: #fff; }
      .big-header-content .link {
        color: #fff; }
    .big-header .quick-access {
      display: none; }
    .big-header .title-js {
      display: none; }
  .navbar {
    padding: 1.25rem; }
    .navbar.scrolled .navbar-brand {
      color: #fff; }
    .navbar.scrolled .hamburger-inner, .navbar.scrolled .hamburger-inner::before, .navbar.scrolled .hamburger-inner::after {
      background-color: #fff; }
  .footer-top {
    padding-top: 0;
    padding-bottom: 1.25rem; }
  .footer-bottom {
    padding: 0.9375rem 0; }
    .footer-bottom-inner {
      flex-direction: column;
      text-align: center; }
  .footer .btn-wrap {
    align-items: flex-start; }
  .has-bg {
    background: none !important; }
  .textside {
    margin: 0;
    padding: 1.25rem; }
  .intro-wrap h2 {
    font-size: 2.1875rem; }
  .articles-masonry-item.md-item, .articles-masonry-item.lg-item {
    width: calc(50% - 10px); }
  .review-wrap {
    background-image: unset;
    padding: 3.125rem 1.875rem;
    margin-bottom: 0; }
    .review-wrap .owl-dots {
      bottom: 20px;
      top: unset; }
  .review-text {
    margin-top: 1.25rem;
    margin-right: 0;
    padding-top: 2.8125rem; }
    .review-text::after {
      bottom: -50px; }
  .content-map {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    display: flex;
    flex-direction: column-reverse; }
    .content-map-filter {
      width: 100%;
      position: static;
      transform: unset;
      padding: 0.9375rem; }
      .content-map-filter span {
        margin-bottom: 10px; }
      .content-map-filter .custom-checkbox {
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 0; }
    .content-map-inner {
      height: 400px; }
  .articles-masonry {
    margin-right: -5px; }
  .gallery-masonsry-item {
    width: calc(100% / 2) !important; } }

@media (max-width: 767px) {
  /* #region Layout */
  body {
    font-size: 0.875rem; }
  .section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  /* #endregion */
  /* #region Typography */
  .h1,
  h1 {
    font-size: 1.875rem; }
  .h2,
  h2,
  .title-30 {
    font-size: 1.75rem; }
  
  .h3,
  .home-desc-inner h2,
  h3 {
    font-size: 1.5rem; }
  .h4,
  h4 {
    font-size: 1.125rem; }
  .intro-wrap h2 {
    font-size: 1.875rem;
    margin-bottom: 0.9375rem; }
  .btn {
    font-size: 0.875rem; }
  /* #endregion */
  /* #region Navbar */
  .navtop-review {
    display: none; }
  .navtop .nav-link {
    height: 20px; }
  /* #endregion */
  .big-header-content .h1 {
    font-size: 2.1875rem; }
  .big-header-content p {
    font-size: 1.125rem;
    margin-top: 0; }
  .articles-masonry-item.no-image .article-wrap {
    min-height: 300px; }
  .articles-masonry-item.md-item, .articles-masonry-item.lg-item {
    width: calc(100% - 10px);
    min-height: 300px; }
  .articles-masonry-item .article-wrap {
    min-height: 300px;
    overflow: hidden; }
    .articles-masonry-item .article-wrap img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      z-index: 0; }
  .articles-masonry-item .article-content {
    left: 0.9375rem;
    bottom: 0.9375rem; }
  .articles-masonry {
    margin-right: 5px;
    margin-left: 15px; } }

@media (max-width: 576px) {
  /* #region Layout */
  .section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  /* #endregion */
  /* #region Typography */
  /* #endregion */
  /* #region Navbar */
  .navtop-search.active .form-control {
    width: calc(100vw - 120px); }
  .navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    .navbar-brand svg {
      height: 40px;
      width: 101px; }
    .navbar .btn {
      display: none; }
  .hamburger {
    padding: 0.625rem 0.5625rem;
    margin-right: -9px; }
  /* #endregion */ }

.big-header.small-size {
  height: 450px; }
  .big-header.small-size::before {
    opacity: 0; }
  .big-header.small-size .big-header-carousel {
    overflow: hidden;
    position: relative; }
    .big-header.small-size .big-header-carousel > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-height: 100%;
      object-fit: cover; }
  .big-header.small-size .h1.title-js {
    top: calc(50% + 20px); }

.big-header.home-design::before {
  display: none; }

.big-header.home-design .big-header-carousel {
  position: relative; }

.big-header.home-design .header-content {
  bottom: unset;
  top: 100px; }
  @media (max-width: 767px) {
    .big-header.home-design .header-content {
      top: 70px; } }

.big-header.home-design .slick-header-carousel {
  width: 100%;
  height: 100%;
  position: relative; }
  .big-header.home-design .slick-header-carousel::after {
    content: "" !important;
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 850px;
    height: 100%;
    background: url(../img/header-shadow.svg) no-repeat 0 0;
    background-size: cover;
    z-index: 0; }
  @media (max-width: 767px) {
    .big-header.home-design .slick-header-carousel {
      height: 450px; } }
  .big-header.home-design .slick-header-carousel .slick-list {
    width: 100%;
    height: 100%; }
  .big-header.home-design .slick-header-carousel .slick-track {
    height: 100%; }
  .big-header.home-design .slick-header-carousel .slick-item-inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative; }
    .big-header.home-design .slick-header-carousel .slick-item-inner img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.big-header.home-design .slick-header-nav {
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 550px; }
  @media (max-width: 767px) {
    .big-header.home-design .slick-header-nav {
      width: calc(100% - 30px);
      bottom: 15px;
      right: 15px; } }
  .big-header.home-design .slick-header-nav .slick-item-inner {
    overflow: hidden;
    height: 80px;
    border: solid 2px #fff; }
    .big-header.home-design .slick-header-nav .slick-item-inner.slick-current {
      border: 2px solid #dc582a; }
    .big-header.home-design .slick-header-nav .slick-item-inner > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (max-width: 767px) {
      .big-header.home-design .slick-header-nav .slick-item-inner {
        height: 70px; } }
  .big-header.home-design .slick-header-nav .slick-track {
    display: flex !important; }
  .big-header.home-design .slick-header-nav .slick-list {
    margin: 0 -5px; }
  .big-header.home-design .slick-header-nav .slick-slide {
    margin: 0 5px; }

.header-scroll-down {
  position: absolute;
  left: 50px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #dc582a; }
  .header-scroll-down svg {
    margin-top: 2px;
    transform: rotate(90deg); }
  .header-scroll-down:hover {
    color: #fff;
    background: #e2734d; }
  @media (max-width: 991px) {
    .header-scroll-down {
      display: none; } }

.cta-wrap.left {
  text-align: left; }

.content p {
  font-size: 1.0625rem; }

.section.darker-bg, .section.bg-darker {
  background-color: rgba(165, 186, 201, 0.3); }
  .section.darker-bg .intro-wrap *, .section.bg-darker .intro-wrap * {
    color: #28385e; }
  .section.darker-bg a:hover, .section.bg-darker a:hover {
    color: #ef9988; }

.section.green-bg {
  background-color: #e0e0d9; }
  .section.green-bg .intro-wrap * {
    color: #fff; }

.browner-bg {
  background-color: #f5f5f7; }

.image-card {
  height: calc(100% - 40px);
  margin-top: 2.5rem;
  display: flex !important;
  flex-direction: column; }
  .image-card:hover .image-card-link img {
    transform: translate(-50%, -50%) scale(1.1); }
  .image-card-link {
    width: 100%;
    padding-bottom: 55%;
    display: block;
    position: relative;
    background: #ef9988;
    overflow: hidden; }
    .image-card-link img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease-out; }
  .image-card-inner {
    background: #f5f5f7;
    padding: 1.5625rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1; }
    .image-card-inner .textside {
      background: unset; }
    .image-card-inner span {
      display: inline-block;
      /*text-transform: unset;*/
      color: #28385e;
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 0.75rem; }
    .image-card-inner h3 {
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: unset;
      margin-bottom: 0.75rem; }
    .image-card-inner p {
      font-size: 1.125rem;
      margin-bottom: 2.5rem; }
    .image-card-inner .textside {
      padding: 0;
      margin-top: 1rem; }
      .image-card-inner .textside p {
        margin-bottom: 1.5625rem; }
    .image-card-inner a {
      margin-top: auto;
      font-size: 1.125rem;
      font-weight: 400;
      text-decoration: none !important;
      color: #ef9988;
      display: inline-flex;
      align-items: center; }
      .image-card-inner a:hover {
        color: #28385e; }

.main-carousel {
  position: static;
  margin-top: 3.125rem; }
  .main-carousel-wrap {
    position: relative; }
  .main-carousel .item {
    position: relative; }
    .main-carousel .item img {
      width: 100%; }
    .main-carousel .item-desc {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem 2.1875rem;
      background: rgba(255, 255, 255, 0.8);
      width: 100%; }
      .main-carousel .item-desc p {
        font-size: 1.0625rem;
        margin-bottom: 0; }

.ct-slick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #28385e;
  cursor: pointer; }
  .ct-slick-wrap {
    list-style: none; }
  .ct-slick.prev {
    left: 0; }
  .ct-slick.next {
    right: 0; }
  .ct-slick.slick-disabled {
    opacity: 0.4; }

.sub-carousel {
  margin-top: 1.25rem; }
  .sub-carousel .slick-slide {
    margin: 0 10px; }
  .sub-carousel .slick-list {
    margin: 0 -10px; }
  .sub-carousel .item-img {
    position: relative;
    height: 185px;
    overflow: hidden; }
    .sub-carousel .item-img img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.accordion-item {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.25rem; }

.accordion-header a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 400;
  color: #28385e;
  position: relative;
  background-color: #fff;
  text-decoration: none;
  padding: 0.8125rem 1.375rem;
  transition: all 0.3s ease-out;
  -webkit-appearance: none; }
  .accordion-header a span {
    max-width: calc(100% - 35px); }
  .accordion-header a:hover {
    color: #ef9988; }
  .accordion-header a:not(.collapsed) {
    color: #fff;
    background: #ef9988;
    text-decoration: none; }
    .accordion-header a:not(.collapsed) svg {
      transform: rotate(180deg); }

.accordion-content-inner {
  background: #fff;
  padding: 1.4375rem 1.375rem;
  font-size: 1.0625rem; }

.accordion.accordion-white {
  margin-left: 5rem; }
  .accordion.accordion-white .accordion-item {
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 0; }
    .accordion.accordion-white .accordion-item:last-child {
      border-bottom: 0; }
  .accordion.accordion-white .accordion-header a {
    padding: 1.875rem; }
    .accordion.accordion-white .accordion-header a:not(.collapsed) {
      color: #ef9988;
      background: rgba(241, 239, 242, 0.5); }
    .accordion.accordion-white .accordion-header a:hover {
      color: #ef9988; }
  .accordion.accordion-white .accordion-content-inner {
    background: rgba(241, 239, 242, 0.5);
    padding: 1.25rem 1.875rem;
    padding-top: 0;
    padding-bottom: 2.5rem; }

.intro-wrap.img-overflow {
  display: flex;
  flex-direction: column; }
  .intro-wrap.img-overflow img {
    align-self: flex-end;
    max-width: 1500px; }

.btn-horizon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.25rem;
  margin-bottom: 1.875rem; }
  .btn-horizon .btn {
    width: 100%;
    max-width: 240px;
    margin: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .btn-horizon .btn {
        font-size: 0.8125rem;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        margin: 0.4375rem; } }
    .btn-horizon .btn.btn-outline {
      background: #fff; }
      .btn-horizon .btn.btn-outline:hover, .btn-horizon .btn.btn-outline:focus {
        background: #dc582a; }

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
  font-size: 0.875rem; }
  .form-group.form-select label {
    top: 0.5rem;
    font-size: 0.75rem;
    color: #152638;
    font-weight: 400;
    user-select: none; }
  .form-group label {
    position: absolute;
    color: #00313c;
    left: 1.1rem;
    top: 1.1rem;
    line-height: 13px;
    margin: 0;
    cursor: text;
    transition: all 0.125s ease-out;
    -webkit-transition: all 0.125s ease-out;
    -moz-transition: all 0.125s ease-out;
    -ms-transition: all 0.125s ease-out;
    -o-transition: all 0.125s ease-out; }
  .form-group .form-control {
    padding: 1.21875rem 1rem 0.3rem 1rem;
    height: 52px;
    border: 1px solid #006a8e;
    border-radius: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    color: #152638 !important;
    background-color: #fff;
    width: 100%; }
    .form-group .form-control::placeholder {
      visibility: hidden;
      color: transparent;
      opacity: 0; }
    .form-group .form-control:focus, .form-group .form-control:not(:placeholder-shown) {
      border-color: #28385e;
      box-shadow: unset; }
      .form-group .form-control:focus::placeholder, .form-group .form-control:not(:placeholder-shown)::placeholder {
        visibility: hidden;
        color: transparent;
        opacity: 0; }
      .form-group .form-control:focus + label, .form-group .form-control:not(:placeholder-shown) + label {
        top: 0.5rem;
        font-size: 0.75rem;
        color: #152638;
        font-weight: 400; }
  .form-group textarea {
    max-width: 100%;
    min-height: 140px; }
  .form-group .bootstrap-select {
    width: 100% !important;
    display: block !important;
    font-size: 0.875rem;
    color: #f5f5f7; }
    .form-group .bootstrap-select > .dropdown-toggle {
      height: 52px;
      padding: 1.21875rem 1rem 0.3rem 1rem;
      border: solid 1px rgba(21, 38, 56, 0.2);
      border-radius: 0;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
      background-color: #fff;
      font-weight: 400; }
      .form-group .bootstrap-select > .dropdown-toggle:focus, .form-group .bootstrap-select > .dropdown-toggle:active, .form-group .bootstrap-select > .dropdown-toggle[aria-expanded="true"], .form-group .bootstrap-select > .dropdown-toggle[title]:not([title=""]) {
        box-shadow: unset;
        border-color: #28385e;
        outline: none !important; }
      .form-group .bootstrap-select > .dropdown-toggle:after {
        margin-right: -5px;
        border: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
        background-size: 24px 24px;
        margin-top: -15px; }
      .form-group .bootstrap-select > .dropdown-toggle .filter-option-inner-inner {
        font-size: 0.875rem; }
    .form-group .bootstrap-select .dropdown-menu.show {
      min-width: 100% !important;
      margin: 0;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      .form-group .bootstrap-select .dropdown-menu.show .dropdown-item {
        padding: 0.5rem 1rem;
        font-size: 0.875rem; }
        .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:hover {
          background-color: #28385e; }
        .form-group .bootstrap-select .dropdown-menu.show .dropdown-item.active, .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:active {
          background-color: #28385e; }
          .form-group .bootstrap-select .dropdown-menu.show .dropdown-item.active:hover, .form-group .bootstrap-select .dropdown-menu.show .dropdown-item:active:hover {
            color: #fff; }

.form-control {
  box-shadow: none !important;
  color: #28385e;
  font-weight: 400; }
  .form-control:focus {
    color: #28385e; }

.custom-control-inline {
  margin-right: 2rem; }

.custom-control-label {
  font-size: 0.875rem; }
  .custom-control-label::before {
    background-color: #f7f7f8;
    border-color: rgba(40, 56, 94, 0.4); }
  .custom-control-label .label-selected {
    display: none; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28385e;
  border-color: #28385e; }

.custom-control-input:checked ~ .custom-control-label .label-selected {
  display: inline; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0rem; }

.custom-control-label::before,
.custom-control-label::after {
  width: 20px;
  height: 20px;
  top: 0;
  left: -1.8rem; }

.custom-control {
  padding-left: 1.875rem; }

.custom-control-input {
  width: 20px;
  height: 20px; }

.custom-checkbox.checkall .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'3'%20viewBox%3D'0%200%2010%203'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M8.9375%200H0.6875C0.300781%200%200%200.322266%200%200.6875V1.375C0%201.76172%200.300781%202.0625%200.6875%202.0625H8.9375C9.30273%202.0625%209.625%201.76172%209.625%201.375V0.6875C9.625%200.322266%209.30273%200%208.9375%200Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E");
  background-size: auto; }

.checkbox-list {
  margin-top: 1.25rem;
  margin-left: 1.875rem; }
  .checkbox-list .custom-checkbox {
    margin-bottom: 0.9375rem; }

.review-item .caption {
  margin-top: 0.9375rem;
  display: inline-block;
  margin-left: 3.125rem;
  color: #fff; }

.narrow {
  width: 80%;
  margin: 0 auto !important; }
  @media (max-width: 575px) {
    .narrow {
      width: 100%; } }

ul:not([class]) {
  padding-left: 1.5625rem; }
  ul:not([class]) li {
    padding-left: 0.4375rem; }
    ul:not([class]) li::marker {
      color: #28385e;
      font-size: 0.875rem;
      content: "\002B";
      color: #1c355e;
      font-size: 1rem;
      font-weight: bold;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  ul:not([class]) span {
    margin-left: 0.3125rem;
    display: flex; }

@media (max-width: 991px) {
  .navbar.scrolled .navtop-search .btn-search {
    color: #ef9988; }
  .big-header.small-size {
    height: 250px; }
  .intro-wrap.img-overflow img {
    width: 100%; }
  .accordion.accordion-white {
    margin-left: 0;
    margin-top: 1.875rem; } }

@media (max-width: 575px) {
  .sub-carousel .item-img {
    height: 80px; } }

.inner-page {
  padding-top: 5rem; }

body.navbar-white {
  padding-top: 5rem; }
  @media (max-width: 1399px) {
    body.navbar-white {
      padding-top: 4.375rem; } }
  @media (max-width: 1199px) {
    body.navbar-white {
      padding-top: 4.375rem; } }
  @media (max-width: 991px) {
    body.navbar-white {
      padding-top: 4.375rem; } }
  @media (max-width: 576px) {
    body.navbar-white {
      padding-top: 3.875rem; } }

.rotate-270 {
  transform: rotate(270deg); }

.faq-search {
  margin-bottom: 5rem; }
  .faq-search .form-group {
    position: relative; }
    .faq-search .form-group .form-control {
      padding-right: 2.5rem; }
  .faq-search .button-submit {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background-color: transparent; }
    .faq-search .button-submit svg {
      transform: rotate(270deg); }

.cta-wrap-multi {
  margin-top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cta-wrap-multi .btn {
    min-width: 220px;
    margin: 0.3125rem 1.5625rem; }

.intro-wrap .note {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 4.375rem;
  font-weight: 400;
  font-size: 1.875rem; }

.review-carousel-nodots .owl-dots {
  display: none; }

.review-wrap.left {
  background-position: left center; }
  .review-wrap.left h2 {
    margin-left: 3.75rem; }
  .review-wrap.left .review-text {
    margin-right: 0;
    margin-left: 3.75rem; }
  .review-wrap.left .review-item .caption {
    display: flex;
    justify-content: flex-end;
    margin-left: unset;
    margin-right: 2.75rem; }

@media (max-width: 991px) {
  .cta-wrap-multi {
    flex-direction: column; }
    .cta-wrap-multi .btn {
      width: 100%;
      max-width: 300px; }
  .review-wrap.left .review-text,
  .review-wrap.left h2 {
    margin-left: 0; }
  .review-wrap .review-item .caption {
    color: #28385e;
    display: flex;
    justify-content: flex-start !important;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 575px) {
  .cta-wrap-multi .btn {
    max-width: unset; } }

.detail-wrap {
  padding-top: 2.5rem; }

.detail-thumbnail, .detail-desc {
  margin-bottom: 1.25rem;
  height: calc(100% - 20px);
  width: 100%;
  padding: 1.5625rem; }

.detail-thumbnail {
  position: relative;
  height: calc(100% - 102px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center; }
  .detail-thumbnail .sold {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 0.8125rem;
    color: #fff;
    background-color: #28385e;
    text-transform: unset; }
  .detail-thumbnail img {
    max-width: 100%; }

.detail-desc {
  background-color: #f5f5f7;
  border-radius: 4px; }
  .detail-desc .feature-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.625rem; }
    .detail-desc .feature-list img {
      margin-right: 0.3125rem; }
    .detail-desc .feature-list span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2.5rem;
      margin-bottom: 0.125rem; }
      .detail-desc .feature-list span:last-child {
        margin-right: 0; }
  .detail-desc h3 {
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: unset;
    margin-bottom: 1.25rem; }
  .detail-desc-inner p {
    font-size: 1.125rem;
    margin-bottom: 1.25rem; }
  .detail-desc-inner ul {
    padding: 0;
    list-style: none;
    margin-bottom: 1.25rem; }
    .detail-desc-inner ul li {
      position: relative;
      padding-left: 2.1875rem;
      margin-bottom: 0.4375rem; }
      .detail-desc-inner ul li::marker {
        content: ""; }
      .detail-desc-inner ul li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 27px;
        height: 24px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'23'%20height%3D'20'%20viewBox%3D'0%200%2023%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M11.6947%200.25C5.53277%200.25%200.567749%204.60059%200.567749%2010C0.567749%2015.3994%205.53277%2019.75%2011.6947%2019.75C17.8567%2019.75%2022.8217%2015.3994%2022.8217%2010C22.8217%208.95117%2022.6613%207.90527%2022.2333%206.92969L20.8424%208.125C21.0129%208.72559%2021.1099%209.32617%2021.1099%2010C21.1099%2014.5762%2016.9172%2018.25%2011.6947%2018.25C6.47228%2018.25%202.27959%2014.5762%202.27959%2010C2.27959%205.42383%206.47228%201.75%2011.6947%201.75C14.2625%201.75%2016.5695%202.64648%2018.1944%204.07031L19.398%203.01562C17.4288%201.29004%2014.6905%200.25%2011.6947%200.25ZM21.3506%203.46094L11.6947%2011.9219L8.03032%208.71094L6.79994%209.78906L11.0795%2013.5391L11.6947%2014.0547L12.3099%2013.5391L22.581%204.53906L21.3506%203.46094Z'%20fill%3D'%2396A480'%2F%3E%3C%2Fsvg%3E") no-repeat center; }
  .detail-desc-inner table {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.9375rem; }
    .detail-desc-inner table tr:nth-of-type(odd) {
      background: #fff; }
    .detail-desc-inner table tr:nth-of-type(even) {
      background: rgba(28, 53, 94, 0.1); }
    .detail-desc-inner table tr td {
      padding: 1rem 1.25rem; }
      .detail-desc-inner table tr td:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.2); }

.btn-wrap.detail {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .btn-wrap.detail .btn {
    margin: 0.3125rem 0.625rem;
    min-width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .btn-wrap.detail .btn-secondary {
      text-transform: unset; }
    @media (max-width: 575px) {
      .btn-wrap.detail .btn {
        width: calc(100% - 20px); } }

.masterplace {
  width: 100%;
  position: relative; }
  .masterplace-img {
    width: 100%;
    height: auto; }
  .masterplace-pin {
    position: absolute;
    font-size: 0.6875rem;
    font-weight: 400;
    min-width: 20px;
    height: 20px;
    border-radius: 20rem;
    color: #fff;
    background: #ef9988;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .masterplace-pin {
        min-width: 12px;
        height: 12px;
        font-size: 0.5625rem; } }
  .masterplace-carousel {
    position: relative; }
    .masterplace-carousel .owl-dots {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%); }
      .masterplace-carousel .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(0, 49, 60, 0.4);
        margin: 5px 10px; }
        .masterplace-carousel .owl-dots .owl-dot.active {
          background-color: #00313c; }

.popover {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); }
  .popover-body {
    font-size: 1.125rem; }

.tooltip.show {
  opacity: 1; }

.tooltip-inner {
  background-color: #fff;
  border-radius: 0.1875rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  font-size: 1.125rem;
  color: #28385e;
  padding: 0.3125rem 0.75rem; }

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #fff; }

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff; }

.navbar:not(.scrolled) .contact-wrap .btn-tel,
.navbar:not(.scrolled) .navtop-search .btn-search, .navbar:not(.navbar-white) .contact-wrap .btn-tel,
.navbar:not(.navbar-white) .navtop-search .btn-search {
  color: #fff; }

.navbar.scrolled .contact-wrap .btn-tel,
.navbar.scrolled .navtop-search .btn-search, .navbar.navbar-white .contact-wrap .btn-tel,
.navbar.navbar-white .navtop-search .btn-search {
  color: #fff; }

.navbar .phone-mobile {
  display: none; }

.breadcrumbs {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  .breadcrumbs * {
    font-size: 0.875rem; }
  .breadcrumbs-link {
    color: #28385e;
    display: inline-block;
    position: relative;
    margin-right: 1.25rem; }
    .breadcrumbs-link::after {
      content: "/";
      position: absolute;
      right: -15px;
      top: 0;
      pointer-events: none; }
    .breadcrumbs-link:hover {
      color: #28385e;
      text-decoration: none; }
      .breadcrumbs-link:hover::after {
        color: #000; }

.blog-filter {
  margin-bottom: 2.5rem;
  padding-left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none; }
  .blog-filter li {
    margin: 0.3125rem; }
    .blog-filter li button {
      display: block;
      color: #28385e;
      text-decoration: none;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1;
      padding: 8px 20px;
      line-height: calc(24 / 16);
      border: solid 1px #28385e;
      border-radius: 10rem;
      background-color: transparent;
      transition: all 0.2s linear;
      text-transform: unset; }
      .blog-filter li button:hover {
        color: #fff;
        background: #28385e; }
      .blog-filter li button.active {
        color: #fff;
        background: #28385e; }

.blog-pagination {
  margin-top: 1.25rem; }
  .blog-pagination ul {
    padding: 0;
    margin-bottom: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none; }
    .blog-pagination ul li a {
      font-size: 1.125rem;
      border-radius: 20rem;
      margin: 0.3125rem;
      color: #000;
      display: block;
      text-decoration: none; }
      .blog-pagination ul li a.active {
        color: #fff;
        background: #ef9988; }
    .blog-pagination ul li:not(.prev):not(.next) a {
      height: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .blog-pagination ul li.prev {
      margin-right: 0.625rem; }
    .blog-pagination ul li.next {
      margin-left: 0.625rem; }

.articles-masonry-item {
  /*.article-content {
        small {
            color:#fff;
        }
    }*/ }
  .articles-masonry-item.pink .article-content * {
    color: #fff; }
  .articles-masonry-item.pink .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ffffff'/%3E%3C/svg%3E") no-repeat;
    background-position: 50%; }
  .articles-masonry-item.brown .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23D1CCBD'/%3E%3C/svg%3E") no-repeat;
    background-position: 50%; }
  .articles-masonry-item.blue .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ABC7C9'/%3E%3C/svg%3E") no-repeat;
    background-position: 50%; }
  .articles-masonry-item.green .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
    background-position: 50%; }
  .articles-masonry-item.darker .article-wrap:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%2300313C'/%3E%3C/svg%3E") no-repeat;
    background-position: 50%; }

a:not([class]) {
  text-decoration: underline; }

a:hover {
  color: #ef9988; }

.elementbenefitscontent .section:not(.grey-bg):not(.brown-bg) .textside {
  background: #e9e7e1; }

.section.brown-bg .accordion.accordion-white .accordion-header a:not(.collapsed) {
  color: #fff;
  background: #ef9988; }

.section.brown-bg .accordion.accordion-white .accordion-content-inner {
  padding: 1.875rem;
  background: #fff; }

.intro-wrap img {
  max-width: 100%;
  height: auto; }

.navbar {
  text-transform: unset; }

.bootstrap-select > select {
  height: 52px !important; }

.userform-dropdown {
  min-height: 52px; }

.dropdown-menu.show .dropdown-menu.inner {
  display: block !important; }

.search-item {
  padding: 2.1875rem 0;
  border-bottom: solid 1px rgba(245, 245, 247, 0.5); }
  .search-item h4 {
    font-size: 1.875rem;
    margin-bottom: 1.25rem; }
    .search-item h4 a {
      color: #28385e;
      text-decoration: none; }
      .search-item h4 a:hover {
        text-decoration: none; }
  .search-item p {
    margin-bottom: 1.5625rem; }
  .search-item .link {
    font-size: 1.0625rem;
    color: #ef9988;
    text-decoration: underline; }

.gallery-masonsry-item {
  width: calc(100% / 3);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px; }
  .gallery-masonsry-item a {
    display: block; }
    .gallery-masonsry-item a img {
      width: 100%;
      height: auto; }

.waving {
  color: #fff; }
  .waving svg {
    width: 100%;
    height: auto; }
  .waving.brown-bg {
    background-color: unset;
    color: #f8f7f8; }
  .waving.grey-bg {
    background-color: unset;
    color: #e9e7e1; }
  .waving.bg-darkder {
    background-color: unset;
    color: #28385e; }
  .waving.bg-pastel, .waving.bg-pink {
    background-color: unset;
    color: #ef9988; }
  .waving.bg-blue {
    background-color: unset;
    color: #ef9988; }
  .waving.bg-brown {
    background-color: unset;
    color: #f5f5f7; }
  .waving.bg-green {
    background-color: unset;
    color: #e0e0d9; }

@keyframes drawing {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: 1550; } }

[data-aos="draw"].aos-animate .path-shadow {
  animation: drawing 2s ease-in-out forwards; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.navbar-collapse {
  justify-content: center; }
  .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center; }
    .navbar-collapse .navbar-nav .nav-item .nav-link {
      font-size: 1.125rem;
      padding: 0 0.625rem;
      margin-left: 0.625rem;
      margin-right: 0.625rem; }
      @media (min-width: 1400px) {
        .navbar-collapse .navbar-nav .nav-item .nav-link.logo {
          margin-left: 1.875rem;
          margin-right: 1.875rem; } }
  .navbar-collapse .nav-aside {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 992px) {
      .navbar-collapse .nav-aside {
        position: static;
        transform: unset; } }
  @media (max-width: 1599px) {
    .navbar-collapse .navbar-nav .nav-item .nav-link {
      margin-left: 0.3125rem;
      margin-right: 0.3125rem; } }
  @media (max-width: 1399px) {
    .navbar-collapse .navbar-nav .nav-item .nav-link {
      font-size: 0.9375rem; }
      .navbar-collapse .navbar-nav .nav-item .nav-link.logo svg {
        width: 200px;
        height: 67px; } }
  @media (max-width: 1199px) {
    .navbar-collapse .navbar-nav .nav-item .nav-link.logo svg {
      width: 150px;
      height: 47px; } }
  @media (max-width: 991px) {
    .navbar-collapse .navbar-nav {
      display: none; } }

header {
  position: relative; }
  header .header-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    height: 100%;
    z-index: 5;
    background: url("../img/textframe.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-left: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    header .header-content h1 {
      font-size: 3.125rem;
      margin-bottom: 1.25rem;
      text-transform: uppercase;
      max-width: 50%; }
      header .header-content h1 span {
        color: #ef9988; }
    header .header-content p {
      font-size: 1.125rem;
      max-width: 50%;
      margin-bottom: 2.1875rem; }
  @media (max-width: 991px) {
    header .header-content {
      width: calc(100% - 40px); }
      header .header-content h1 {
        font-size: 2.1875rem;
        margin-bottom: 0.9375rem; }
      header .header-content p {
        font-size: 1.125rem;
        margin-bottom: 0.9375rem; } }
  @media (max-width: 767px) {
    header .header-content {
      left: 0;
      width: calc(100% - 20px);
      padding-left: 0.9375rem; } }
  header.small-size .header-content {
    /* bottom: unset;
            top: 50px; */ }
  header.small-size .big-header-carousel .header-carousel .owl-stage-outer:after {
    /*visibility: hidden;*/ }

.feature-carousel .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .feature-carousel .item-img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.875rem; }
    .feature-carousel .item-img img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
      object-fit: contain; }
  .feature-carousel .item p {
    font-size: 1.25rem;
    font-weight: 500;
    color: #28385e;
    text-transform: unset; }

.feature-carousel .item-grid {
  row-gap: 3.125rem; }
  @media (max-width: 991px) {
    .feature-carousel .item-grid {
      row-gap: 1.875rem; } }
  .feature-carousel .item-grid .item {
    margin: 0;
    flex-direction: row;
    column-gap: 0.9375rem;
    text-align: left; }
    .feature-carousel .item-grid .item .item-img {
      width: 60px;
      height: 60px;
      margin: 0;
      flex-shrink: 0; }
  .feature-carousel .item-grid p {
    margin: 0; }

.weatherwidget-io {
  position: fixed !important;
  right: 0;
  top: 120px;
  width: 110px;
  z-index: 4;
  transform: scale(0.7);
  opacity: 1;
  transition: opacity 0.2s ease-out;
  transform-origin: top right; }
  .weatherwidget-io.scrolled {
    opacity: 0;
    pointer-events: none; }
  @media (max-width: 1399px) {
    .weatherwidget-io {
      top: 107px; } }
  @media (max-width: 1199px) {
    .weatherwidget-io {
      top: 87px; } }
  @media (max-width: 991px) {
    .weatherwidget-io {
      top: unset;
      bottom: 0;
      transform: scale(0.7);
      transform-origin: bottom right; }
      .weatherwidget-io.scrolled {
        opacity: 0; } }

body:not(.homepage) .weatherwidget-io {
  display: none !important; }

.image-text {
  position: relative; }
  .image-text > .image {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
    border-radius: 300px 300px 0px 0px; }
  .image-text .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 400;
    width: 100%; }
    .image-text .text span {
      font-weight: 400 !important; }
    .image-text .text h6 {
      display: inline-block;
      font-size: rem-cacl(14);
      color: #ef9988;
      margin-bottom: 0.9375rem; }
    .image-text .text h2 {
      font-size: 2.625rem;
      line-height: calc(52 / 42);
      color: #28385e;
      margin-bottom: 2.5rem;
      max-width: 500px; }
      @media (max-width: 991px) {
        .image-text .text h2 {
          font-size: 1.875rem;
          margin-bottom: 1.5625rem; } }
    .image-text .text p {
      max-width: 600px;
      color: #28385e; }
    .image-text .text ul {
      padding: 0;
      margin: 0;
      margin-bottom: 0.625rem;
      list-style: none; }
      .image-text .text ul li {
        padding-left: 1.875rem;
        position: relative;
        margin-bottom: 0.9375rem;
        color: #28385e; }
        .image-text .text ul li::marker {
          display: none;
          content: none; }
        .image-text .text ul li::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23f09988'%2F%3E%3C%2Fsvg%3E") no-repeat; }
    .image-text .text-padding {
      padding-left: 3.125rem; }
      @media (max-width: 991px) {
        .image-text .text-padding {
          padding-left: 0; } }
    .image-text .text-padding-right {
      padding-right: 3.125rem; }
      @media (max-width: 991px) {
        .image-text .text-padding-right {
          padding-right: 0; } }
  .image-text .image-side {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .image-text .image-side .inner {
      position: relative;
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .image-text .image-side .inner {
          padding-bottom: 2.5rem; } }
      .image-text .image-side .inner img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 2; }
        @media (max-width: 767px) {
          .image-text .image-side .inner img {
            width: calc(100% - 20px); } }
      .image-text .image-side .inner::before {
        content: "";
        background-color: #ef9988;
        position: absolute;
        bottom: 1.25rem;
        width: 85%;
        height: 80%;
        z-index: 1;
        display: none; }
    .image-text .image-side.left .inner {
      justify-content: flex-start; }
      .image-text .image-side.left .inner::before {
        right: 0;
        border-bottom-right-radius: 2.1875rem; }
        @media (max-width: 767px) {
          .image-text .image-side.left .inner::before {
            border-bottom-right-radius: 1.25rem; } }
    .image-text .image-side:not(.left) .inner {
      justify-content: flex-end; }
      .image-text .image-side:not(.left) .inner::before {
        left: 0;
        border-bottom-left-radius: 2.1875rem; }
        @media (max-width: 767px) {
          .image-text .image-side:not(.left) .inner::before {
            border-bottom-left-radius: 1.25rem; } }
  .image-text.left > .image {
    left: 0; }
  .image-text.right > .image {
    right: 0; }

.community-carousel .slick-slide,
.home-carousel .slick-slide {
  margin: 0 15px;
  height: inherit !important; }

.community-carousel .slick-list,
.home-carousel .slick-list {
  margin: 0 -15px; }

.community-carousel .slick-track,
.home-carousel .slick-track {
  display: flex !important; }

.darker-bg {
  background: #b6c1cb; }

.testimonial-wrap {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  @media (max-width: 767px) {
    .testimonial-wrap {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }

.testimonial-carousel {
  margin-bottom: 0;
  padding-bottom: 5rem;
  border-bottom: dashed 2px #ffffff; }
  @media (max-width: 991px) {
    .testimonial-carousel {
      padding-bottom: 2.8125rem; } }
  .testimonial-carousel .owl-dots {
    display: none;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%); }
    .testimonial-carousel .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgba(239, 153, 136, 0.4);
      margin: 0.625rem;
      transition: background-color 0.2s ease-out; }
      .testimonial-carousel .owl-dots .owl-dot.active, .testimonial-carousel .owl-dots .owl-dot:hover {
        background: #ef9988; }
  .testimonial-carousel .owl-nav {
    display: flex;
    column-gap: 0.625rem; }
    .testimonial-carousel .owl-nav button.owl-prev,
    .testimonial-carousel .owl-nav button.owl-next {
      width: 36px;
      height: 36px;
      border: solid 1px #28385e !important;
      border-radius: 50%;
      background-color: #28385e !important;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'11'%20viewBox%3D'0%200%2018%2011'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M5.10938%2010.5591L6.16406%209.48096L2.95313%206.27002L18%206.27002L18%204.77002L2.95313%204.77002L6.16406%201.55908L5.10938%200.480956L0.0703129%205.52002L5.10938%2010.5591Z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
      background-position: center !important;
      background-repeat: no-repeat !important; }
      .testimonial-carousel .owl-nav button.owl-prev span,
      .testimonial-carousel .owl-nav button.owl-next span {
        display: none; }
    .testimonial-carousel .owl-nav button.owl-next {
      transform: rotate(180deg); }

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .testimonial-item .name {
    font-size: 1.25rem;
    display: inline-block;
    margin-bottom: 1.25rem; }
  .testimonial-item .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1.5rem;
    margin-bottom: 2.5rem; }
    .testimonial-item .info .rate {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.25rem; }
      .testimonial-item .info .rate svg {
        color: #ffffff; }
    .testimonial-item .info .date {
      font-size: 1.125rem; }

.testimonial-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0.9375rem; }
  .testimonial-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.testimonial-text {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1.875rem;
  max-width: 700px; }
  @media (max-width: 767px) {
    .testimonial-text {
      font-size: 1.5625rem; } }

.testimonial-wrap.darker-bg {
  /*.testimonial-text {
            color: #fff;
        }
        */ }

.cta-block {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #28385e;
  background-image: url("../img/letter.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%; }
  @media (min-width: 992px) {
    .cta-block {
      min-height: 570px; } }
  .cta-block .img-side {
    width: 55%;
    position: relative;
    flex-shrink: 0;
    clip-path: circle(100% at 7% 21%); }
    .cta-block .img-side > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (max-width: 991px) {
      .cta-block .img-side {
        clip-path: circle(100% at 0 0); } }
  .cta-block .text-side {
    width: 45%;
    padding: 4.375rem 3.75rem;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #ffffff; }
    .cta-block .text-side h2 {
      font-size: 2.625rem;
      margin-bottom: 1.25rem;
      color: #ffffff; }
    .cta-block .text-side p {
      margin-bottom: 2.1875rem;
      color: #ffffff; }
  @media (max-width: 991px) {
    .cta-block {
      flex-direction: column; }
      .cta-block .img-side {
        width: 100%;
        height: auto; }
        .cta-block .img-side > img {
          width: 100%;
          height: auto; }
      .cta-block .text-side {
        width: 100%;
        padding: 3.125rem 1.5625rem; }
        .cta-block .text-side h2 {
          font-size: 1.875rem;
          margin-bottom: 0.9375rem; }
        .cta-block .text-side p {
          margin-bottom: 1.25rem; } }

.big-header.home-design .big-header-carousel .slick-list::after {
  display: none; }

.home-designs-page .home-designs-icon-block .section {
  padding-bottom: 0px; }

.container.narrow {
  max-width: 600px; }

.hide {
  display: none; }

@media (min-width: 1200px) {
  .col-xl.dv-5 {
    flex: 0 0 calc(100% / 5); } }

body.homedesign .big-header.home-design .slick-header-carousel:after {
  display: none; }

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.embed-wrap > iframe {
  width: 100%;
  max-height: calc(100vh - 92px); }
  @media (max-width: 576px) {
    .embed-wrap > iframe {
      max-height: calc(100vh - 81px); } }

body.no-layout {
  padding-top: 0; }
  body.no-layout .fixed-top,
  body.no-layout .subscribe,
  body.no-layout .footer {
    display: none; }
  body.no-layout .embed-wrap > iframe {
    max-height: calc(100vh - 23px); }
    @media (max-width: 576px) {
      body.no-layout .embed-wrap > iframe {
        max-height: calc(100vh - 23px); } }

.item-thumb-carousel .thumb-tag {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 3px;
  background: #28385e;
  color: #fff;
  font-weight: 300;
  padding: 0.125rem 0.625rem;
  z-index: 1; }

.item-thumb-carousel .slick-thumb-carousel,
.item-thumb-carousel .slick-thumb-nav {
  width: auto;
  height: 100%; }
  .item-thumb-carousel .slick-thumb-carousel .slick-item-inner,
  .item-thumb-carousel .slick-thumb-nav .slick-item-inner {
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    overflow: hidden; }
    .item-thumb-carousel .slick-thumb-carousel .slick-item-inner > img,
    .item-thumb-carousel .slick-thumb-nav .slick-item-inner > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.item-thumb-carousel .slick-thumb-carousel .slick-prev,
.item-thumb-carousel .slick-thumb-carousel .slick-next {
  position: absolute;
  top: calc(50% - 18px);
  z-index: 99;
  background: #28385e;
  border: 0px;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center; }

.item-thumb-carousel .slick-thumb-carousel .slick-prev {
  left: 15px; }

.item-thumb-carousel .slick-thumb-carousel .slick-next {
  right: 15px; }

.item-thumb-carousel .slick-thumb-carousel .slick-track {
  display: flex !important; }

.item-thumb-carousel .slick-thumb-carousel .slick-slide {
  height: inherit !important; }

@media (max-width: 991px) {
  .item-thumb-carousel .slick-thumb-nav {
    margin-left: -5px;
    margin-right: -5px; } }

.item-thumb-carousel .slick-thumb-nav .slick-item {
  margin-bottom: 0.75rem; }
  @media (max-width: 991px) {
    .item-thumb-carousel .slick-thumb-nav .slick-item {
      margin-bottom: 0;
      padding-left: 5px;
      padding-right: 5px; } }
  .item-thumb-carousel .slick-thumb-nav .slick-item.slick-current .slick-item-inner {
    position: relative; }
    .item-thumb-carousel .slick-thumb-nav .slick-item.slick-current .slick-item-inner::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }

.home-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }
  @media (max-width: 991px) {
    .home-desc {
      margin-bottom: 1.875rem; } }
  .home-desc .h2 {
    margin-bottom: 0.9375rem; }
  .home-desc .style {
    margin-bottom: 1.875rem;
    font-weight: 400; }
    .home-desc .style span {
      font-weight: 300;
      text-transform: unset; }
    @media (max-width: 991px) {
      .home-desc .style {
        margin-bottom: 1.25rem; } }
  .home-desc .feature-list {
    margin-bottom: 1.875rem;
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .home-desc .feature-list span {
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .home-desc .feature-list span img {
        margin-right: 0.5rem; }
      .home-desc .feature-list span + span {
        margin-left: 2.5rem; }
    @media (max-width: 991px) {
      .home-desc .feature-list {
        margin-bottom: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; }
        .home-desc .feature-list span + span {
          margin-left: 1.375rem; } }
  .home-desc-inner h2,
  .home-desc-inner h3,
  .home-desc-inner h4,
  .home-desc-inner h5,
  .home-desc-inner h6 {
    color: #ef9988;
    margin-bottom: 1.875rem; }
    @media (max-width: 991px) {
      .home-desc-inner h2,
      .home-desc-inner h3,
      .home-desc-inner h4,
      .home-desc-inner h5,
      .home-desc-inner h6 {
        margin-bottom: 0.9375rem; } }

.home-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  padding: 1.875rem 3.125rem;
  border-radius: 0.625rem; }
  .home-contact .h3, .home-contact .home-desc-inner h2, .home-desc-inner .home-contact h2 {
    margin-bottom: 1.25rem;
    color: #28385e;
    font-weight: 300; }
  .home-contact h5 {
    font-size: 1.125rem;
    font-weight: 400;
    text-underline-offset: 2px;
    margin-bottom: 0.625rem; }
  .home-contact p {
    margin-bottom: 0; }
  .home-contact .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2.1875rem; }
    .home-contact .btn-wrap .btn {
      width: 100%; }
      .home-contact .btn-wrap .btn + .btn {
        margin-top: 0.9375rem; }
  @media (max-width: 991px) {
    .home-contact {
      padding: 1.25rem 1.875rem; }
      .home-contact .btn-wrap {
        margin-top: 1.25rem; }
        .home-contact .btn-wrap .btn + .btn {
          margin-top: 0.625rem; } }

.home-floorplan-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%; }
  .home-floorplan-text .sub {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #ef9988; }
  .home-floorplan-text .h3, .home-floorplan-text .home-desc-inner h2, .home-desc-inner .home-floorplan-text h2 {
    margin-bottom: 1.25rem; }
  .home-floorplan-text p {
    margin-bottom: 0; }
  .home-floorplan-text .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2.1875rem; }
    .home-floorplan-text .btn-wrap .btn {
      width: 100%; }
      .home-floorplan-text .btn-wrap .btn + .btn {
        margin-top: 0.9375rem; }

@media (max-width: 991px) {
  .home-floorplan-text {
    margin-bottom: 1.875rem; }
    .home-floorplan-text .btn-wrap {
      margin-top: 1.25rem; }
      .home-floorplan-text .btn-wrap .btn + .btn {
        margin-top: 0.625rem; } }

.new-arrow .ct-slick {
  color: #28385e;
  background: white;
  width: 50px;
  border: solid 1px #28385e;
  height: 24px;
  border-radius: 10rem; }
  .new-arrow .ct-slick.slick-disabled {
    opacity: 0.3; }

.homefinder {
  position: relative;
  z-index: 3; }

.custom-image-card-inner {
  background: none;
  padding-left: 0;
  padding-right: 0; }
  .custom-image-card-inner h3 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold; }
  .custom-image-card-inner span {
    display: inline-block;
    border: 1px solid #ef9988;
    color: #ef9988;
    border-radius: 0.3125rem;
    padding: 0.125rem 0.5rem;
    font-weight: 400;
    font-size: 0.8125rem; }

.custom-image-card-link {
  background: none !important;
  border-radius: 0.625rem; }

.custom-featured-post {
  background: #fff;
  border: 1px solid #e9e7e1; }

.custom-image-featured-post {
  border-radius: 0.3125rem 0.3125rem 0 0; }

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem; }

.positon-index {
  position: relative;
  z-index: 2; }

.imagetext-wrap {
  position: relative; }
  @media (min-width: 992px) {
    .imagetext-wrap {
      min-height: 500px;
      display: flex;
      align-items: center; } }
  @media (max-width: 991px) {
    .imagetext-wrap {
      display: flex;
      flex-direction: column-reverse; } }
  .imagetext-wrap .image-side {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .imagetext-wrap .image-side:not(.right) {
      left: 0;
      clip-path: circle(100% at 14% 8%); }
    .imagetext-wrap .image-side.right {
      right: 0;
      clip-path: circle(100% at 86% 8%); }
    .imagetext-wrap .image-side > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (max-width: 991px) {
      .imagetext-wrap .image-side {
        position: static;
        width: 100%;
        padding-bottom: 1.5625rem;
        clip-path: circle(100% at 10% 5%) !important; } }

.vimeo-row,
.youtube-row {
  row-gap: 1.875rem; }

.vimeo-btn,
.youtube-btn {
  display: block;
  cursor: pointer;
  text-decoration: none;
  position: relative; }
  .vimeo-btn:hover, .vimeo-btn:focus,
  .youtube-btn:hover,
  .youtube-btn:focus {
    text-decoration: none; }
  .vimeo-btn > .images,
  .youtube-btn > .images {
    margin-bottom: 0.9375rem; }
  .vimeo-btn .height,
  .youtube-btn .height {
    margin-bottom: 0; }
  .vimeo-btn .btn-play,
  .youtube-btn .btn-play {
    width: 60px;
    height: 60px;
    min-height: unset;
    object-fit: unset;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 0;
    background: transparent;
    border: none; }
    .vimeo-btn .btn-play img,
    .youtube-btn .btn-play img {
      width: 100%;
      height: 100%;
      position: static;
      transform: unset; }
