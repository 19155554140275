.detail {
  &-wrap {
    padding-top: rem-calc(40);
  }
  &-thumbnail,
  &-desc {
    margin-bottom: rem-calc(20);
    height: calc(100% - 20px);
    width: 100%;
    padding: rem-calc(25);
  }
  &-thumbnail {
    position: relative;
    height: calc(100% - 102px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    .sold {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      font-size: rem-calc(13);
      color: #fff;
      background-color: $color-darker;
      text-transform: unset;
    }
    img {
      max-width: 100%;
    }
  }
  &-desc {
    background-color: #f5f5f7;
    border-radius: 4px;
    .feature-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: rem-calc(10);
      img {
        margin-right: rem-calc(5);
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem-calc(40);
        margin-bottom: rem-calc(2);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    h3 {
      font-size: rem-calc(24);
      font-weight: 400;
      text-transform: unset;
      margin-bottom: rem-calc(20);
    }
    &-inner {
      p {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(20);
      }
      ul {
        padding: 0;
        list-style: none;
        margin-bottom: rem-calc(20);
        li {
          position: relative;
          padding-left: rem-calc(35);
          margin-bottom: rem-calc(7);
          &::marker {
            content: "";
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 27px;
            height: 24px;
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'23'%20height%3D'20'%20viewBox%3D'0%200%2023%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M11.6947%200.25C5.53277%200.25%200.567749%204.60059%200.567749%2010C0.567749%2015.3994%205.53277%2019.75%2011.6947%2019.75C17.8567%2019.75%2022.8217%2015.3994%2022.8217%2010C22.8217%208.95117%2022.6613%207.90527%2022.2333%206.92969L20.8424%208.125C21.0129%208.72559%2021.1099%209.32617%2021.1099%2010C21.1099%2014.5762%2016.9172%2018.25%2011.6947%2018.25C6.47228%2018.25%202.27959%2014.5762%202.27959%2010C2.27959%205.42383%206.47228%201.75%2011.6947%201.75C14.2625%201.75%2016.5695%202.64648%2018.1944%204.07031L19.398%203.01562C17.4288%201.29004%2014.6905%200.25%2011.6947%200.25ZM21.3506%203.46094L11.6947%2011.9219L8.03032%208.71094L6.79994%209.78906L11.0795%2013.5391L11.6947%2014.0547L12.3099%2013.5391L22.581%204.53906L21.3506%203.46094Z'%20fill%3D'%2396A480'%2F%3E%3C%2Fsvg%3E")
              no-repeat center;
          }
        }
      }
      table {
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: rem-calc(15);
        tr {
          &:nth-of-type(odd) {
            background: #fff;
          }
          &:nth-of-type(even) {
            background: rgba(#1c355e, 0.1);
          }
          td {
            padding: rem-calc(16) rem-calc(20);
            &:first-child {
              border-right: 1px solid rgba(#000000, 0.2);
            }
          }
        }
      }
    }
  }
}

.btn-wrap.detail {
  margin-left: rem-calc(-10);
  margin-right: rem-calc(-10);
  .btn {
    margin: rem-calc(5) rem-calc(10);
    min-width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &-secondary {
      text-transform: unset;
    }
    @media (max-width: 575px) {
      width: calc(100% - 20px);
    }
  }
}

.masterplace {
  width: 100%;
  position: relative;
  &-img {
    width: 100%;
    height: auto;
  }
  &-pin {
    position: absolute;
    font-size: rem-calc(11);
    font-weight: 400;
    min-width: 20px;
    height: 20px;
    border-radius: 20rem;
    color: #fff;
    background: $color-pastel;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: 12px;
      height: 12px;
      font-size: rem-calc(9);
    }
  }
  &-carousel {
    position: relative;
    .owl-dots {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(0, 49, 60, 0.4);
        margin: 5px 10px;
        &.active {
          background-color: #00313c;
        }
      }
    }
  }
}

.popover {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  &-body {
    font-size: rem-calc(18);
  }
}

.tooltip {
  &.show {
    opacity: 1;
  }
  &-inner {
    background-color: #fff;
    border-radius: rem-calc(3);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    font-size: rem-calc(18);
    color: $color-darker;
    padding: rem-calc(5) rem-calc(12);
  }
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff;
}
