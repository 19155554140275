@media (min-width: 1300px) {
  /* #region Layout */
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1230px;
  }
  /* #endregion */
}

@media (min-width: 1500px) {
  /* #region Layout */
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1330px;
  }
  /* #endregion */
}

@media (max-width: 1399px) {
  /* #region Navbar */
  .navtop,
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar {
    &-brand {
      margin-right: 1rem;
    }
    .btn {
      padding-left: rem-calc(6);
      padding-right: rem-calc(6);
      white-space: nowrap;
    }
    &-nav .nav-item .nav-link {
      font-size: rem-calc(12);
      padding-left: rem-calc(8);
      padding-right: rem-calc(8);
    }
  }
  .navtop-search {
    margin-left: 0;
    margin-right: 0;
  }
  .contact-wrap {
    .btn-tel {
      font-size: rem-calc(13);
      margin: 0 rem-calc(10);
    }
    .btn {
      font-size: rem-calc(13);
      padding: rem-calc(12);
      &.btn-w160 {
        min-width: 110px;
      }
    }
  }
  .navbar-brand {
    svg,
    img {
      width: 140px;
      height: auto;
    }
  }
  .navbar.navbar-white,
  .navbar.scrolled {
    padding: 10px 15px;
  }
  /* #endregion */
}

@media (max-width: 991px) {
  /* #region Layout */
  /* #endregion */

  /* #region Typography */
  .h1,
  h1 {
    font-size: rem-calc(38);
  }
  .h2,
  h2 {
    font-size: rem-calc(30);
  }
  .h3,
  h3 {
    font-size: rem-calc(26);
  }
  .h4,
  h4 {
    font-size: rem-calc(20);
  }
  /* #endregion */

  /* #region Navbar */
  .navbar {
    &-nav {
      display: none;
    }
    .btn {
      padding-left: rem-calc(22);
      padding-right: rem-calc(22);
    }
  }
  .hamburger {
    display: block;
  }
  /* #endregion */
  .navtop-search .btn-search {
    color: #fff;
  }
  .contact-wrap {
    .icon-phone {
      color: #fff;
      margin-left: rem-calc(15);
    }
  }
  .phone-mobile {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: rgba(#000, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: rem-calc(30);
    a {
      display: block;
      width: 100%;
      height: 50px;
      background-color: #fff;
      border-radius: rem-calc(3);
      color: #0091ff;
      text-align: left;
      line-height: rem-calc(50);
      margin-bottom: rem-calc(20);
      font-size: rem-calc(18);
      padding: 0 rem-calc(20);
      &:hover {
        color: #0091ff;
        text-decoration: none;
      }
      &.cancel {
        text-align: center;
        font-weight: 400;
      }
      svg {
        margin-right: rem-calc(15);
        fill {
          color: #d2d2d2;
        }
      }
    }
  }
  .big-header {
    height: auto;
    min-height: 450px;
    &-carousel {
      width: 100%;
      .header-carousel .owl-stage-outer:after {
        width: 100%;
      }
      .owl-item-inner {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color-darker, 0.2);
          z-index: 2;
        }
      }
      .owl-dots {
        width: 100%;
        left: 0 !important;
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-content {
      width: 100%;
      position: absolute;
      z-index: 3;
      background: transparent;
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      padding: 20px 30px;
      .h1 {
        font-size: rem-calc(50);
        color: #fff;
        white-space: normal;
      }
      p {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(15);
        font-size: rem-calc(20);
        color: #fff;
      }
      .link {
        color: #fff;
      }
    }
    .quick-access {
      display: none;
    }
    .title-js {
      display: none;
    }
  }
  .navbar {
    padding: rem-calc(20);
    &.scrolled {
      .navbar-brand {
        color: #fff;
      }
      .hamburger-inner {
        &,
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
  .footer {
    &-top {
      padding-top: 0;
      padding-bottom: rem-calc(20);
    }
    &-bottom {
      padding: rem-calc(15) 0;
      &-inner {
        flex-direction: column;
        text-align: center;
      }
    }
    .btn-wrap {
      align-items: flex-start;
    }
  }
  .has-bg {
    background: none !important;
  }
  .textside {
    margin: 0;
    padding: rem-calc(20);
  }
  .intro-wrap {
    h2 {
      font-size: rem-calc(35);
    }
  }
  .articles-masonry-item {
    &.md-item,
    &.lg-item {
      width: calc(50% - 10px);
    }
  }
  .review-wrap {
    background-image: unset;
    padding: rem-calc(50) rem-calc(30);
    margin-bottom: 0;
    .owl-dots {
      bottom: 20px;
      top: unset;
    }
  }
  .review-text {
    margin-top: rem-calc(20);
    margin-right: 0;
    padding-top: rem-calc(45);
    &::after {
      bottom: -50px;
    }
  }
  .content-map {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    display: flex;
    flex-direction: column-reverse;
    &-filter {
      width: 100%;
      position: static;
      transform: unset;
      padding: rem-calc(15);
      span {
        margin-bottom: 10px;
      }
      .custom-checkbox {
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 0;
      }
    }
    &-inner {
      height: 400px;
    }
  }
  .articles-masonry {
    margin-right: -5px;
  }
  .gallery-masonsry {
    &-item {
      width: calc(100% / 2) !important;
    }
  }
}

@media (max-width: 767px) {
  /* #region Layout */
  body {
    font-size: rem-calc(14);
  }
  .section {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
  }
  /* #endregion */

  /* #region Typography */
  .h1,
  h1 {
    font-size: rem-calc(30);
  }
  .h2,
  h2,
  .title-30 {
    font-size: rem-calc(28);
  }
  .h3,
  h3 {
    font-size: rem-calc(24);
  }
  .h4,
  h4 {
    font-size: rem-calc(18);
  }
  .intro-wrap {
    h2 {
      font-size: rem-calc(30);
      margin-bottom: rem-calc(15);
    }
  }
  .btn {
    font-size: rem-calc(14);
  }
  /* #endregion */

  /* #region Navbar */
  .navtop-review {
    display: none;
  }
  .navtop .nav-link {
    height: 20px;
  }
  /* #endregion */
  .big-header-content {
    .h1 {
      font-size: rem-calc(35);
    }
    p {
      font-size: rem-calc(18);
      margin-top: 0;
    }
  }
  .articles-masonry-item.no-image .article-wrap {
    min-height: 300px;
  }
  .articles-masonry-item {
    &.md-item,
    &.lg-item {
      width: calc(100% - 10px);
      min-height: 300px;
    }
    .article-wrap {
      min-height: 300px;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        z-index: 0;
      }
    }
    .article-content {
      left: rem-calc(15);
      bottom: rem-calc(15);
    }
  }
  .articles-masonry {
    margin-right: 5px;
    margin-left: 15px;
  }
}

@media (max-width: 576px) {
  /* #region Layout */
  .section {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
  }
  /* #endregion */

  /* #region Typography */
  /* #endregion */

  /* #region Navbar */
  .navtop-search.active .form-control {
    width: calc(100vw - 120px);
  }
  .navbar {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    &-brand svg {
      height: 40px;
      width: 101px;
    }
    .btn {
      display: none;
    }
  }
  .hamburger {
    padding: rem-calc(10) rem-calc(9);
    margin-right: -9px;
  }
  /* #endregion */
}
