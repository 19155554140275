.feature {
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: rem-calc(30);
    margin-top: rem-calc(20);
    p {
      max-width: 280px;
      font-size: rem-calc(20);
      font-weight: 400;
      text-transform: unset;
    }
  }
  &-img {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem-calc(30);
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
p.message.error,
span.message.required {
  color: #ff0000;
}
.userform {
  .field {
    &.recaptcha {
      padding-left: 10px;
      margin-bottom: 20px;
    }
  }
}
